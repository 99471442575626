import {Kingdoms, KingdomName} from './kingdom';
import State from './state';
import {GenerateOptions} from './Input';

export type MoonID = number;

export class Moon {
    name: string;
    count: number;
    kingdom: KingdomName;
    prerequisite_kingdoms: Array<[KingdomName, number]>;
    prerequisite_moons: Array<MoonID>;
    prerequisite_moon_count: number;

    constructor(name: string, kingdom: KingdomName, multi: boolean = false) {
        this.name = name;
        if (multi) {
            this.count = 3;
        } else {
            this.count = 1;
        }
        this.kingdom = kingdom;
        this.prerequisite_kingdoms = [];
        this.prerequisite_moons = [];
        this.prerequisite_moon_count = 0;
    }

    add_prereq_kingdom(kingdom: KingdomName): void {
        this.prerequisite_kingdoms.push([kingdom, 1]);
    }

    add_prereq_kingdom_count(kingdom: KingdomName, visited: number): void {
        this.prerequisite_kingdoms.push([kingdom, visited]);
    }

    add_prereq_moon(moon: MoonID): void {
        this.prerequisite_moons.push(moon);
    }

    set_prereq_moon_count(count: number): void {
        this.prerequisite_moon_count = count;
    }

    available(state: State): boolean {
        // current kingdom has to be the kingdom this moon is in
        if (state.current_kingdom !== this.kingdom) {
            return false;
        }

        // all prerequisite kingdoms must be scheduled
        for (let [p, c] of this.prerequisite_kingdoms) {
            if (!state.kingdom_scheduled(p, c)) {
                return false;
            }
        }

        // all prerequisite moons must be scheduled
        for (let p of this.prerequisite_moons) {
            if (!state.moon_scheduled(p)) {
                return false;
            }
        }

        // enough moons need to be scheduled
        if (state.total_moons < this.prerequisite_moon_count) {
            return false;
        }

        return true;
    }
}

export class Moons {
    moons: Array<Moon>;
    offset: Array<[number, number]>;
    ids: Array<MoonID>;

    moon(id: MoonID): Moon {
        return this.moons[id];
    }

    return_available(state: State): Array<MoonID> {
        let kingdom = state.current_kingdom;
        let [s, e] = this.offset[kingdom];
        if (s === e) {
            // no moons left in kingdom
            state.complete_kingdom(kingdom);
            return [];
        }

        // go from start to end, check availability
        let ret = [];
        let swap_point = s;
        for (let cc = s; cc < e; cc++) {
            let id = this.ids[cc];
            if (this.moons[id].available(state)) {
                ret.push(id);
                // swap x with start
                this.ids[cc] = this.ids[swap_point];
                this.ids[swap_point] = id;
                swap_point += 1;
            }
        }

        // finally update start by the amount copied out
        let new_s = swap_point;
        this.offset[kingdom] = [new_s, e];
        return ret;
    }

    constructor(kingdoms: Kingdoms, options: GenerateOptions) {
        this.moons = [];
        this.offset = [];

        // cap kingdom
        let cap1 = this.moons.length;
        this.moons.push(new Moon("Frog-Jumping Above the Fog", KingdomName.Cap));
        this.moons[cap1].add_prereq_kingdom(KingdomName.Cascade);
        let cap2 = this.moons.length;
        this.moons.push(new Moon("Frog-Jumping from the Top Deck", KingdomName.Cap));
        this.moons[cap2].add_prereq_kingdom(KingdomName.Cascade);
        let cap3 = this.moons.length;
        this.moons.push(new Moon("Cap Kingdom Timer Challenge 1", KingdomName.Cap));
        this.moons[cap3].add_prereq_kingdom(KingdomName.Cascade);
        let cap4 = this.moons.length;
        this.moons.push(new Moon("Good Evening, Captain Toad!", KingdomName.Cap));
        this.moons[cap4].add_prereq_kingdom(KingdomName.Cascade);
        let cap5 = this.moons.length;
        this.moons.push(new Moon("Shopping in Bonneton", KingdomName.Cap));
        this.moons[cap5].add_prereq_kingdom(KingdomName.Cascade);
        let cap6 = this.moons.length;
        this.moons.push(new Moon("Skimming the Poison Tide", KingdomName.Cap));
        this.moons[cap6].add_prereq_kingdom(KingdomName.Cascade);
        let cap7 = this.moons.length;
        this.moons.push(new Moon("Slipping Through the Poison Tide", KingdomName.Cap));
        this.moons[cap7].add_prereq_kingdom(KingdomName.Cascade);
        let cap8 = this.moons.length;
        this.moons.push(new Moon("Push-Block Peril", KingdomName.Cap));
        this.moons[cap8].add_prereq_kingdom(KingdomName.Cascade);
        let cap9 = this.moons.length;
        this.moons.push(new Moon("Hidden Among the Push-Blocks", KingdomName.Cap));
        this.moons[cap9].add_prereq_kingdom(KingdomName.Cascade);
        let cap10 = this.moons.length;
        this.moons.push(new Moon("Searching the Frog Pond", KingdomName.Cap));
        this.moons[cap10].add_prereq_kingdom(KingdomName.Cascade);
        let cap11 = this.moons.length;
        this.moons.push(new Moon("Secrets of the Frog Pond", KingdomName.Cap));
        this.moons[cap11].add_prereq_kingdom(KingdomName.Cascade);
        let cap12 = this.moons.length;
        this.moons.push(new Moon("The Forgotten Treasure", KingdomName.Cap));
        this.moons[cap12].add_prereq_kingdom(KingdomName.Mushroom);
        let cap13 = this.moons.length;
        this.moons.push(new Moon("Taxi Flying Through Bonneton", KingdomName.Cap));
        this.moons[cap13].add_prereq_kingdom(KingdomName.Mushroom);
        let cap14 = this.moons.length;
        this.moons.push(new Moon("Bonneter Blockade", KingdomName.Cap));
        this.moons[cap14].add_prereq_kingdom(KingdomName.Mushroom);
        let cap15 = this.moons.length;
        this.moons.push(new Moon("Cap Kingdom Regular Cup", KingdomName.Cap));
        this.moons[cap15].add_prereq_kingdom(KingdomName.Mushroom);
        let cap16 = this.moons.length;
        this.moons.push(new Moon("Peach in the Cap Kingdom", KingdomName.Cap));
        this.moons[cap16].add_prereq_kingdom(KingdomName.Mushroom);
        let cap18 = this.moons.length;
        this.moons.push(new Moon("Next to Glasses Bridge", KingdomName.Cap));
        this.moons[cap18].add_prereq_kingdom(KingdomName.Mushroom);
        let cap19 = this.moons.length;
        this.moons.push(new Moon("Danger Sign", KingdomName.Cap));
        this.moons[cap19].add_prereq_kingdom(KingdomName.Mushroom);
        let cap20 = this.moons.length;
        this.moons.push(new Moon("Under the Big One's Brim", KingdomName.Cap));
        this.moons[cap20].add_prereq_kingdom(KingdomName.Mushroom);
        let cap21 = this.moons.length;
        this.moons.push(new Moon("Fly to the Edge of the Fog", KingdomName.Cap));
        this.moons[cap21].add_prereq_kingdom(KingdomName.Mushroom);
        let cap22 = this.moons.length;
        this.moons.push(new Moon("Spin the Hat, Get a Prize", KingdomName.Cap));
        this.moons[cap22].add_prereq_kingdom(KingdomName.Mushroom);
        let cap23 = this.moons.length;
        this.moons.push(new Moon("Hidden in a Sunken Hat", KingdomName.Cap));
        this.moons[cap23].add_prereq_kingdom(KingdomName.Mushroom);
        let cap24 = this.moons.length;
        this.moons.push(new Moon("Fog-Shrouded Platform", KingdomName.Cap));
        this.moons[cap24].add_prereq_kingdom(KingdomName.Mushroom);
        let cap25 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling in the Fog", KingdomName.Cap));
        this.moons[cap25].add_prereq_kingdom(KingdomName.Mushroom);
        let cap26 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping Near the Ship!", KingdomName.Cap));
        this.moons[cap26].add_prereq_kingdom(KingdomName.Mushroom);
        let cap27 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In the Fog", KingdomName.Cap));
        this.moons[cap27].add_prereq_kingdom(KingdomName.Mushroom);
        let cap28 = this.moons.length;
        this.moons.push(new Moon("Cap Kingdom Timer Challenge 2", KingdomName.Cap));
        this.moons[cap28].add_prereq_kingdom(KingdomName.Mushroom);
        let cap29 = this.moons.length;
        this.moons.push(new Moon("Cap Kingdom Master Cup", KingdomName.Cap));
        this.moons[cap29].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cap29].add_prereq_moon(cap15);
        let cap30 = this.moons.length;
        this.moons.push(new Moon("Roll On and On", KingdomName.Cap));
        this.moons[cap30].add_prereq_kingdom(KingdomName.Mushroom);
        let cap31 = this.moons.length;
        this.moons.push(new Moon("Precision Rolling", KingdomName.Cap));
        this.moons[cap31].add_prereq_kingdom(KingdomName.Mushroom);
        let mushroom41 = this.moons.length;
        this.moons.push(new Moon("Found with Mushroom Kingdom Art", KingdomName.Cap));
        this.moons[mushroom41].add_prereq_kingdom(KingdomName.Mushroom);
        this.offset.push([cap1, this.moons.length]);

        // cascade kingdom
        let cascade1 = this.moons.length;
        this.moons.push(new Moon("Our First Power Moon", KingdomName.Cascade));
        let cascade2 = this.moons.length;
        this.moons.push(new Moon("Multi Moon Atop the Falls", KingdomName.Cascade, true));
        this.moons[cascade2].add_prereq_moon(cascade1);
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Cascade).set_exit_moon(cascade2);
        }
        let cascade3 = this.moons.length;
        this.moons.push(new Moon("Chomp Through the Rocks", KingdomName.Cascade));
        this.moons[cascade3].add_prereq_moon(cascade1);
        let cascade4 = this.moons.length;
        this.moons.push(new Moon("Behind the Waterfall", KingdomName.Cascade));
        this.moons[cascade4].add_prereq_moon(cascade1);
        let cascade5 = this.moons.length;
        this.moons.push(new Moon("On Top of the Rubble", KingdomName.Cascade));
        this.moons[cascade5].add_prereq_moon(cascade2);
        let cascade6 = this.moons.length;
        this.moons.push(new Moon("Treasure of the Waterfall Basin", KingdomName.Cascade));
        this.moons[cascade6].add_prereq_moon(cascade2);
        let cascade7 = this.moons.length;
        this.moons.push(new Moon("Above a High Cliff", KingdomName.Cascade));
        this.moons[cascade7].add_prereq_moon(cascade2);
        let cascade8 = this.moons.length;
        this.moons.push(new Moon("Across the Floating Isles", KingdomName.Cascade));
        this.moons[cascade8].add_prereq_moon(cascade2);
        let cascade9 = this.moons.length;
        this.moons.push(new Moon("Cascade Kingdom Timer Challenge 1", KingdomName.Cascade));
        this.moons[cascade9].add_prereq_moon(cascade2);
        let cascade10 = this.moons.length;
        this.moons.push(new Moon("Cascade Kingdom Timer Challenge 2", KingdomName.Cascade));
        this.moons[cascade10].add_prereq_moon(cascade2);
        let cascade11 = this.moons.length;
        this.moons.push(new Moon("Good Morning, Captain Toad!", KingdomName.Cascade));
        this.moons[cascade11].add_prereq_moon(cascade2);
        let cascade12 = this.moons.length;
        this.moons.push(new Moon("Dinosaur Nest: Big Cleanup!", KingdomName.Cascade));
        this.moons[cascade12].add_prereq_moon(cascade2);
        let cascade13 = this.moons.length;
        this.moons.push(new Moon("Dinosaur Nest: Running Wild!", KingdomName.Cascade));
        this.moons[cascade13].add_prereq_moon(cascade2);
        let cascade14 = this.moons.length;
        this.moons.push(new Moon("Nice Shot with the Chain Chomp!", KingdomName.Cascade));
        this.moons[cascade14].add_prereq_moon(cascade2);
        let cascade15 = this.moons.length;
        this.moons.push(new Moon("Very Nice Shot with the Chain Chomp!", KingdomName.Cascade));
        this.moons[cascade15].add_prereq_moon(cascade2);
        let cascade16 = this.moons.length;
        this.moons.push(new Moon("Past the Chasm Lifts", KingdomName.Cascade));
        this.moons[cascade16].add_prereq_moon(cascade2);
        let cascade17 = this.moons.length;
        this.moons.push(new Moon("Hidden Chasm Passage", KingdomName.Cascade));
        this.moons[cascade17].add_prereq_moon(cascade2);
        let cascade18 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Fossil Falls", KingdomName.Cascade));
        let cascade19 = this.moons.length;
        this.moons.push(new Moon("A Tourist in the Cascade Kingdom", KingdomName.Cascade));
        let cascade20 = this.moons.length;
        this.moons.push(new Moon("Rolling Rock by the Falls", KingdomName.Cascade));
        this.moons[cascade20].add_prereq_kingdom_count(KingdomName.Cascade, 2);
        let cascade21 = this.moons.length;
        this.moons.push(new Moon("Peach in the Cascade Kingdom", KingdomName.Cascade));
        this.moons[cascade21].add_prereq_kingdom(KingdomName.Mushroom);
        let cascade22 = this.moons.length;
        this.moons.push(new Moon("Cascade Kingdom Regular Cup", KingdomName.Cascade));
        this.moons[cascade22].add_prereq_kingdom(KingdomName.Mushroom);
        let cascade23 = this.moons.length;
        this.moons.push(new Moon("Caveman Cave-Fan", KingdomName.Cascade));
        this.moons[cascade23].add_prereq_kingdom(KingdomName.Mushroom);
        let cascade24 = this.moons.length;
        this.moons.push(new Moon("Shopping in Fossil Falls", KingdomName.Cascade));
        this.moons[cascade24].add_prereq_kingdom_count(KingdomName.Cascade, 2);
        let cascade25 = this.moons.length;
        this.moons.push(new Moon("Sphynx Traveling to the Waterfall", KingdomName.Cascade));
        this.moons[cascade25].add_prereq_kingdom(KingdomName.Mushroom);
        let cascade26 = this.moons.length;
        this.moons.push(new Moon("Bottom of the Waterfall Basin", KingdomName.Cascade));
        this.moons[cascade26].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade26].add_prereq_moon(cascade2);
        let cascade27 = this.moons.length;
        this.moons.push(new Moon("Just a Hat, Skip, and a Jump", KingdomName.Cascade));
        this.moons[cascade27].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade27].add_prereq_moon(cascade2);
        let cascade28 = this.moons.length;
        this.moons.push(new Moon("Treasure Under the Cliff", KingdomName.Cascade));
        this.moons[cascade28].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade28].add_prereq_moon(cascade2);
        let cascade29 = this.moons.length;
        this.moons.push(new Moon("Next to the Stone Arch", KingdomName.Cascade));
        this.moons[cascade29].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade29].add_prereq_moon(cascade2);
        let cascade30 = this.moons.length;
        this.moons.push(new Moon("Guarded by a Colossal Fossil", KingdomName.Cascade));
        this.moons[cascade30].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade30].add_prereq_moon(cascade2);
        let cascade31 = this.moons.length;
        this.moons.push(new Moon("Under the Old Electrical Pole", KingdomName.Cascade));
        this.moons[cascade31].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade31].add_prereq_moon(cascade2);
        let cascade32 = this.moons.length;
        this.moons.push(new Moon("Under the Ground", KingdomName.Cascade));
        this.moons[cascade32].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade32].add_prereq_moon(cascade2);
        let cascade33 = this.moons.length;
        this.moons.push(new Moon("Inside the Busted Fossil", KingdomName.Cascade));
        this.moons[cascade33].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade33].add_prereq_moon(cascade2);
        let cascade34 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping at the Waterfall", KingdomName.Cascade));
        this.moons[cascade34].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade34].add_prereq_moon(cascade2);
        let cascade35 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Hurry Upward", KingdomName.Cascade));
        this.moons[cascade35].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade35].add_prereq_moon(cascade2);
        let cascade36 = this.moons.length;
        this.moons.push(new Moon("Cascade Kingdom Master Cup", KingdomName.Cascade));
        this.moons[cascade36].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade36].add_prereq_moon(cascade2);
        this.moons[cascade36].add_prereq_moon(cascade22);
        let cascade37 = this.moons.length;
        this.moons.push(new Moon("Across the Mysterious Clouds", KingdomName.Cascade));
        this.moons[cascade37].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade37].add_prereq_moon(cascade2);
        let cascade38 = this.moons.length;
        this.moons.push(new Moon("Atop a Wall Among the Clouds", KingdomName.Cascade));
        this.moons[cascade38].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade38].add_prereq_moon(cascade2);
        let cascade39 = this.moons.length;
        this.moons.push(new Moon("Across the Gusty Bridges", KingdomName.Cascade));
        this.moons[cascade39].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade39].add_prereq_moon(cascade2);
        let cascade40 = this.moons.length;
        this.moons.push(new Moon("Flying Far Away from Gusty Bridges", KingdomName.Cascade));
        this.moons[cascade40].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cascade40].add_prereq_moon(cascade2);
        let lake27 = this.moons.length;
        this.moons.push(new Moon("Found with Lake Kingdom Art", KingdomName.Cascade));
        this.moons[lake27].add_prereq_kingdom(KingdomName.Lake);
        this.moons[lake27].add_prereq_kingdom_count(KingdomName.Cascade, 2);
        let dark15 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 1", KingdomName.Cascade));
        this.moons[dark15].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([cascade1, this.moons.length]);

        // sand kingdom
        let sand1 = this.moons.length;
        this.moons.push(new Moon("Atop the Highest Tower", KingdomName.Sand));
        let sand2 = this.moons.length;
        this.moons.push(new Moon("Moon Shards in the Sand", KingdomName.Sand));
        this.moons[sand2].add_prereq_moon(sand1);
        let sand3 = this.moons.length;
        this.moons.push(new Moon("Showdown on the Inverted Pyramid", KingdomName.Sand, true));
        if (!options.ipclip) {
            this.moons[sand3].add_prereq_moon(sand2);
        }
        let sand4 = this.moons.length;
        this.moons.push(new Moon("The Hole in the Desert", KingdomName.Sand, true));
        this.moons[sand4].add_prereq_moon(sand3);
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Sand).set_exit_moon(sand4);
        }
        this.moons.push(new Moon("Overlooking the Desert Town", KingdomName.Sand));
        this.moons.push(new Moon("Alcove in the Ruins", KingdomName.Sand));
        this.moons.push(new Moon("On the Leaning Pillar", KingdomName.Sand));
        this.moons.push(new Moon("Hidden Room in the Flowing Sands", KingdomName.Sand));
        let sand9 = this.moons.length;
        this.moons.push(new Moon("Secret of the Mural", KingdomName.Sand));
        let sand10 = this.moons.length;
        this.moons.push(new Moon("Secret of the Inverted Mural", KingdomName.Sand));
        if (!options.ipclip) {
            this.moons[sand10].add_prereq_moon(sand4);
        }
        this.moons.push(new Moon("On Top of the Stone Archway", KingdomName.Sand));
        this.moons.push(new Moon("From a Crate in the Ruins", KingdomName.Sand));
        this.moons.push(new Moon("On the Lone Pillar", KingdomName.Sand));
        let sand14 = this.moons.length;
        this.moons.push(new Moon("On the Statue's Tail", KingdomName.Sand));
        if (!options.ipclip) {
            this.moons[sand14].add_prereq_moon(sand4);
        }
        let sand15 = this.moons.length;
        this.moons.push(new Moon("Hang Your Hat on the Fountain", KingdomName.Sand));
        this.moons[sand15].add_prereq_moon(sand4);
        let sand16 = this.moons.length;
        this.moons.push(new Moon("Where the Birds Gather", KingdomName.Sand));
        let sand17 = this.moons.length;
        this.moons.push(new Moon("Top of a Dune", KingdomName.Sand));
        let sand18 = this.moons.length;
        this.moons.push(new Moon("Lost in the Luggage", KingdomName.Sand));
        let sand19 = this.moons.length;
        this.moons.push(new Moon("Bullet Bill Breakthrough", KingdomName.Sand));
        this.moons[sand19].add_prereq_moon(sand4);
        this.moons.push(new Moon("Inside a Block is a Hard Place", KingdomName.Sand));
        let sand21 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling the Desert", KingdomName.Sand));
        let sand22 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling the Wastes", KingdomName.Sand));
        this.moons[sand22].add_prereq_moon(sand4);
        let sand23 = this.moons.length;
        this.moons.push(new Moon("The Lurker Under the Stone", KingdomName.Sand));
        this.moons[sand23].add_prereq_moon(sand4);
        let sand24 = this.moons.length;
        this.moons.push(new Moon("The Treasure of Jaxi Ruins", KingdomName.Sand));
        let sand25 = this.moons.length;
        this.moons.push(new Moon("Desert Gardening: Plaza Seed", KingdomName.Sand));
        let sand26 = this.moons.length;
        this.moons.push(new Moon("Desert Gardening: Ruins Seed", KingdomName.Sand));
        let sand27 = this.moons.length;
        this.moons.push(new Moon("Desert Gardening: Seed on the Cliff", KingdomName.Sand));
        let sand28 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Timer Challenge 1", KingdomName.Sand));
        this.moons[sand28].add_prereq_moon(sand4);
        let sand29 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Timer Challenge 2", KingdomName.Sand));
        this.moons[sand29].add_prereq_moon(sand4);
        let sand30 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Timer Challenge 3", KingdomName.Sand));
        this.moons[sand30].add_prereq_moon(sand4);
        let sand31 = this.moons.length;
        this.moons.push(new Moon("Found in the Sand! Good Dog!", KingdomName.Sand));
        this.moons[sand31].add_prereq_moon(sand4);
        let sand32 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Jump on the Palm", KingdomName.Sand));
        let sand33 = this.moons.length;
        this.moons.push(new Moon("Herding Sheep in the Dunes", KingdomName.Sand));
        let sand34 = this.moons.length;
        this.moons.push(new Moon("Fishing in the Oasis", KingdomName.Sand));
        this.moons[sand34].add_prereq_moon(sand4);
        let sand35 = this.moons.length;
        this.moons.push(new Moon("Love in the Heart of the Desert", KingdomName.Sand));
        this.moons[sand35].add_prereq_moon(sand4);
        this.moons.push(new Moon("Among the Five Cactuses", KingdomName.Sand));
        let sand37 = this.moons.length;
        this.moons.push(new Moon("You're Quite a Catch, Captain Toad!", KingdomName.Sand));
        this.moons[sand37].add_prereq_moon(sand4);
        let sand38 = this.moons.length;
        this.moons.push(new Moon("Jaxi Reunion!", KingdomName.Sand));
        this.moons[sand38].add_prereq_moon(sand4);
        let sand39 = this.moons.length;
        this.moons.push(new Moon("Welcome Back, Jaxi!", KingdomName.Sand));
        this.moons[sand39].add_prereq_moon(sand4);
        let sand40 = this.moons.length;
        this.moons.push(new Moon("Wandering Cactus", KingdomName.Sand));
        let sand41 = this.moons.length;
        this.moons.push(new Moon("Sand Quiz: Wonderful!", KingdomName.Sand));
        this.moons.push(new Moon("Shopping in Tostarena", KingdomName.Sand));
        this.moons.push(new Moon("Employees Only", KingdomName.Sand));
        let sand44 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Slots", KingdomName.Sand));
        let sand45 = this.moons.length;
        this.moons.push(new Moon("Walking the Desert", KingdomName.Sand));
        let sand46 = this.moons.length;
        this.moons.push(new Moon("Hidden Room in the Inverted Pyramid", KingdomName.Sand));
        if (!options.ipclip) {
            this.moons[sand46].add_prereq_moon(sand4);
        }
        let sand47 = this.moons.length;
        this.moons.push(new Moon("Underground Treasure Chest", KingdomName.Sand));
        this.moons[sand47].add_prereq_moon(sand3);
        let sand48 = this.moons.length;
        this.moons.push(new Moon("Goomba Tower Assembly", KingdomName.Sand));
        this.moons[sand48].add_prereq_moon(sand3);
        let sand49 = this.moons.length;
        this.moons.push(new Moon("Under the Mummy's Curse", KingdomName.Sand));
        this.moons[sand49].add_prereq_moon(sand4);
        this.moons.push(new Moon("Ice Cave Treasure", KingdomName.Sand));
        let sand51 = this.moons.length;
        this.moons.push(new Moon("Sphynx's Treasure Vault", KingdomName.Sand));
        let sand52 = this.moons.length;
        this.moons.push(new Moon("A Rumble from the Sandy Floor", KingdomName.Sand));
        let sand53 = this.moons.length;
        this.moons.push(new Moon("Dancing with New Friends", KingdomName.Sand));
        this.moons.push(new Moon("The Invisible Maze", KingdomName.Sand));
        let sand55 = this.moons.length;
        this.moons.push(new Moon("Skull Sign in the Transparent Maze", KingdomName.Sand));
        this.moons.push(new Moon("The Bullet Bill Maze: Break Through!", KingdomName.Sand));
        this.moons.push(new Moon("The Bullet Bill Maze: Side Path", KingdomName.Sand));
        this.moons.push(new Moon("Jaxi Driver", KingdomName.Sand));
        this.moons.push(new Moon("Jaxi Stunt Driving", KingdomName.Sand));
        let sand60 = this.moons.length;
        this.moons.push(new Moon("Strange Neighborhood", KingdomName.Sand));
        this.moons[sand60].add_prereq_moon(sand4);
        let sand61 = this.moons.length;
        this.moons.push(new Moon("Above a Strange Neighborhood", KingdomName.Sand));
        this.moons[sand61].add_prereq_moon(sand4);
        let sand62 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Tostarena!", KingdomName.Sand));
        this.moons[sand62].add_prereq_kingdom(KingdomName.Lake);
        let sand64 = this.moons.length;
        this.moons.push(new Moon("Jammin' in the Sand Kingdom", KingdomName.Sand));
        this.moons[sand64].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand64].add_prereq_moon(sand4);
        let sand65 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek: In the Sand", KingdomName.Sand));
        this.moons[sand65].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand65].add_prereq_moon(sand4);
        let sand66 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Regular Cup", KingdomName.Sand));
        this.moons[sand66].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand66].add_prereq_moon(sand4);
        let sand67 = this.moons.length;
        this.moons.push(new Moon("Binding Band Returned", KingdomName.Sand));
        this.moons[sand67].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand67].add_prereq_moon(sand4);
        let sand68 = this.moons.length;
        this.moons.push(new Moon("Round-the-World Tourist", KingdomName.Sand));
        this.moons[sand68].add_prereq_moon(sand4);
        let sand69 = this.moons.length;
        this.moons.push(new Moon("Peach in the Sand Kingdom", KingdomName.Sand));
        this.moons[sand69].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand69].add_prereq_moon(sand4);
        let sand70 = this.moons.length;
        this.moons.push(new Moon("Mighty Leap from the Palm Tree!", KingdomName.Sand));
        this.moons[sand70].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand70].add_prereq_moon(sand4);
        let sand71 = this.moons.length;
        this.moons.push(new Moon("On the North Pillar", KingdomName.Sand));
        this.moons[sand71].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand71].add_prereq_moon(sand4);
        let sand72 = this.moons.length;
        this.moons.push(new Moon("Into the Flowing Sands", KingdomName.Sand));
        this.moons[sand72].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand72].add_prereq_moon(sand4);
        let sand73 = this.moons.length;
        this.moons.push(new Moon("In the Skies Above the Canyon", KingdomName.Sand));
        this.moons[sand73].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand73].add_prereq_moon(sand4);
        let sand74 = this.moons.length;
        this.moons.push(new Moon("Island in the Poison Swamp", KingdomName.Sand));
        this.moons[sand74].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand74].add_prereq_moon(sand4);
        let sand75 = this.moons.length;
        this.moons.push(new Moon("An Invisible Gleam", KingdomName.Sand));
        this.moons[sand75].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand75].add_prereq_moon(sand4);
        let sand76 = this.moons.length;
        this.moons.push(new Moon("On the Eastern Pillar", KingdomName.Sand));
        this.moons[sand76].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand76].add_prereq_moon(sand4);
        let sand77 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping in the Desert!", KingdomName.Sand));
        this.moons[sand77].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand77].add_prereq_moon(sand4);
        let sand78 = this.moons.length;
        this.moons.push(new Moon("Poster Cleanup", KingdomName.Sand));
        this.moons[sand78].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand78].add_prereq_moon(sand4);
        let sand79 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Running Down", KingdomName.Sand));
        this.moons[sand79].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand79].add_prereq_moon(sand4);
        let sand80 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In the Wall Painting", KingdomName.Sand));
        this.moons[sand80].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand80].add_prereq_moon(sand4);
        let sand81 = this.moons.length;
        this.moons.push(new Moon("Love at the Edge of the Desert", KingdomName.Sand));
        this.moons[sand81].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand81].add_prereq_moon(sand4);
        let sand82 = this.moons.length;
        this.moons.push(new Moon("More Walking in the Desert!", KingdomName.Sand));
        this.moons[sand82].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand82].add_prereq_moon(sand45);
        this.moons[sand82].add_prereq_moon(sand4);
        let sand83 = this.moons.length;
        this.moons.push(new Moon("Sand Kingdom Master Cup", KingdomName.Sand));
        this.moons[sand83].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand83].add_prereq_moon(sand66);
        this.moons[sand83].add_prereq_moon(sand4);
        let sand84 = this.moons.length;
        this.moons.push(new Moon("Where the Transparent Platforms End", KingdomName.Sand));
        this.moons[sand84].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand84].add_prereq_moon(sand4);
        let sand85 = this.moons.length;
        this.moons.push(new Moon("Jump Onto the Transparent Lift", KingdomName.Sand));
        this.moons[sand85].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand85].add_prereq_moon(sand4);
        let sand86 = this.moons.length;
        this.moons.push(new Moon("Colossal Ruins: Dash! Jump!", KingdomName.Sand));
        this.moons[sand86].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand86].add_prereq_moon(sand4);
        let sand87 = this.moons.length;
        this.moons.push(new Moon("Sinking Colossal Ruins: Hurry!", KingdomName.Sand));
        this.moons[sand87].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand87].add_prereq_moon(sand4);
        this.moons[sand87].add_prereq_moon(sand86);
        let sand88 = this.moons.length;
        this.moons.push(new Moon("Through the Freezing Waterway", KingdomName.Sand));
        this.moons[sand88].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand88].add_prereq_moon(sand4);
        let sand89 = this.moons.length;
        this.moons.push(new Moon("Freezing Waterway: Hidden Room", KingdomName.Sand));
        this.moons[sand89].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand89].add_prereq_moon(sand4);
        let wooded50 = this.moons.length;
        this.moons.push(new Moon("Found with Wooded Kingdom Art", KingdomName.Sand));
        this.moons[wooded50].add_prereq_kingdom(KingdomName.Wooded);
        this.moons[wooded50].add_prereq_kingdom_count(KingdomName.Sand, 2);
        let bowser45 = this.moons.length;
        this.moons.push(new Moon("Found with Bowser's Kingdom Art", KingdomName.Sand));
        this.moons[bowser45].add_prereq_kingdom(KingdomName.Bowser);
        this.moons[bowser45].add_prereq_kingdom_count(KingdomName.Sand, 2);
        this.offset.push([sand1, this.moons.length]);

        // lake kingdom
        let lake1 = this.moons.length;
        this.moons.push(new Moon("Broodals Over the Lake", KingdomName.Lake, true));
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Lake).set_exit_moon(lake1);
        }
        this.moons.push(new Moon("Dorrie-Back Rider", KingdomName.Lake));
        this.moons.push(new Moon("Cheep Cheep Crossing", KingdomName.Lake));
        this.moons.push(new Moon("End of the Hidden Passage", KingdomName.Lake));
        this.moons.push(new Moon("What's in the Box?", KingdomName.Lake));
        let lake6 = this.moons.length;
        this.moons.push(new Moon("On the Lakeshore", KingdomName.Lake));
        let lake7 = this.moons.length;
        this.moons.push(new Moon("From the Broken Pillar", KingdomName.Lake));
        let lake8 = this.moons.length;
        this.moons.push(new Moon("Treasure in the Spiky Waterway", KingdomName.Lake));
        let lake9 = this.moons.length;
        this.moons.push(new Moon("Lake Gardening: Spiky Passage Seed", KingdomName.Lake));
        this.moons[lake9].add_prereq_moon(lake1);
        let lake10 = this.moons.length;
        this.moons.push(new Moon("Lake Kingdom Timer Challenge 1", KingdomName.Lake));
        this.moons[lake10].add_prereq_moon(lake1);
        let lake11 = this.moons.length;
        this.moons.push(new Moon("Lake Kingdom Timer Challenge 2", KingdomName.Lake));
        this.moons[lake11].add_prereq_moon(lake1);
        this.moons.push(new Moon("Moon Shards in the Lake", KingdomName.Lake));
        let lake13 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Dive and Swim", KingdomName.Lake));
        let lake14 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In the Cliffside", KingdomName.Lake));
        let lake15 = this.moons.length;
        this.moons.push(new Moon("Lake Fishing", KingdomName.Lake));
        this.moons[lake15].add_prereq_moon(lake1);
        let lake16 = this.moons.length;
        this.moons.push(new Moon("I Met a Lake Cheep Cheep!", KingdomName.Lake));
        this.moons[lake16].add_prereq_moon(lake1);
        this.moons.push(new Moon("Our Secret Little Room", KingdomName.Lake));
        let lake18 = this.moons.length;
        this.moons.push(new Moon("Let's Go Swimming, Captain Toad!", KingdomName.Lake));
        this.moons.push(new Moon("Shopping in Lake Lamode", KingdomName.Lake));
        let lake20 = this.moons.length;
        this.moons.push(new Moon("A Successful Repair Job", KingdomName.Lake));
        this.moons[lake20].add_prereq_moon(lake1);
        let lake21 = this.moons.length;
        this.moons.push(new Moon("I Feel Underdressed", KingdomName.Lake));
        this.moons.push(new Moon("Unzip the Chasm", KingdomName.Lake));
        this.moons.push(new Moon("Super-Secret Zipper", KingdomName.Lake));
        this.moons.push(new Moon("Jump, Grab, Cling, and Climb", KingdomName.Lake));
        let lake25 = this.moons.length;
        this.moons.push(new Moon("Jump, Grab, and Climb Some More", KingdomName.Lake));
        let lake26 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Lake Lamode!", KingdomName.Lake));
        this.moons[lake27].add_prereq_moon(lake1);
        let lake28 = this.moons.length;
        this.moons.push(new Moon("Taxi Flying Through Lake Lamode", KingdomName.Lake));
        this.moons[lake28].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake28].add_prereq_moon(lake1);
        let lake29 = this.moons.length;
        this.moons.push(new Moon("That Trendy \"Pirate\" Look", KingdomName.Lake));
        this.moons[lake29].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake29].add_prereq_moon(lake1);
        let lake30 = this.moons.length;
        this.moons.push(new Moon("Space Is \"In\" Right Now", KingdomName.Lake));
        this.moons[lake30].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake30].add_prereq_moon(lake1);
        let lake31 = this.moons.length;
        this.moons.push(new Moon("That \"Old West\" Style", KingdomName.Lake));
        this.moons[lake31].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake31].add_prereq_moon(lake1);
        let lake32 = this.moons.length;
        this.moons.push(new Moon("Lake Kingdom Regular Cup", KingdomName.Lake));
        this.moons[lake32].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake32].add_prereq_moon(lake1);
        let lake33 = this.moons.length;
        this.moons.push(new Moon("Peach in the Lake Kingdom", KingdomName.Lake));
        this.moons[lake33].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake33].add_prereq_moon(lake1);
        let lake34 = this.moons.length;
        this.moons.push(new Moon("Behind the Floodgate", KingdomName.Lake));
        this.moons[lake34].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake34].add_prereq_moon(lake1);
        let lake35 = this.moons.length;
        this.moons.push(new Moon("High-Flying Leap", KingdomName.Lake));
        this.moons[lake35].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake35].add_prereq_moon(lake1);
        let lake36 = this.moons.length;
        this.moons.push(new Moon("Deep, Deep Down", KingdomName.Lake));
        this.moons[lake36].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake36].add_prereq_moon(lake1);
        let lake37 = this.moons.length;
        this.moons.push(new Moon("Rooftop of the Water Plaza", KingdomName.Lake));
        this.moons[lake37].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake37].add_prereq_moon(lake1);
        let lake38 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling Over the Lake", KingdomName.Lake));
        this.moons[lake38].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake38].add_prereq_moon(lake1);
        let lake39 = this.moons.length;
        this.moons.push(new Moon("Love by the Lake", KingdomName.Lake));
        this.moons[lake39].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake39].add_prereq_moon(lake1);
        let lake40 = this.moons.length;
        this.moons.push(new Moon("Lake Kingdom Master Cup", KingdomName.Lake));
        this.moons[lake40].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake40].add_prereq_moon(lake32);
        this.moons[lake40].add_prereq_moon(lake1);
        let lake41 = this.moons.length;
        this.moons.push(new Moon("Waves of Poison: Hoppin' Over", KingdomName.Lake));
        this.moons[lake41].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake41].add_prereq_moon(lake1);
        let lake42 = this.moons.length;
        this.moons.push(new Moon("Waves of Poison: Hop to It!", KingdomName.Lake));
        this.moons[lake42].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lake42].add_prereq_moon(lake1);
        let metro53 = this.moons.length;
        this.moons.push(new Moon("Found With Metro Kingdom Art", KingdomName.Lake));
        this.moons[metro53].add_prereq_kingdom(KingdomName.Metro);
        let dark23 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 9", KingdomName.Lake));
        this.moons[dark23].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([lake1, this.moons.length]);

        // wooded kingdom
        let wooded1 = this.moons.length;
        this.moons.push(new Moon("Road to Sky Garden", KingdomName.Wooded));
        let wooded2 = this.moons.length;
        this.moons.push(new Moon("Flower Thieves of Sky Garden", KingdomName.Wooded, true));
        this.moons[wooded2].add_prereq_moon(wooded1);
        this.moons[sand62].add_prereq_moon(wooded2);
        let wooded3 = this.moons.length;
        this.moons.push(new Moon("Path to the Secret Flower Field", KingdomName.Wooded));
        this.moons[wooded3].add_prereq_moon(wooded2);
        let wooded4 = this.moons.length;
        this.moons.push(new Moon("Defend the Secret Flower Field!", KingdomName.Wooded, true));
        this.moons[wooded4].add_prereq_moon(wooded3);
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Wooded).set_exit_moon(wooded4);
        }
        let wooded5 = this.moons.length;
        this.moons.push(new Moon("Behind the Rock Wall", KingdomName.Wooded));
        this.moons[wooded5].add_prereq_moon(wooded2);
        let wooded6 = this.moons.length;
        this.moons.push(new Moon("Back Way Up the Mountain", KingdomName.Wooded));
        this.moons[wooded6].add_prereq_moon(wooded2);
        this.moons.push(new Moon("Rolling Rock in the Woods", KingdomName.Wooded));
        let wooded8 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping in the Forest!", KingdomName.Wooded));
        let wooded9 = this.moons.length;
        this.moons.push(new Moon("Thanks for the Charge!", KingdomName.Wooded));
        this.moons[wooded9].add_prereq_moon(wooded2);
        this.moons.push(new Moon("Atop the Tall Tree", KingdomName.Wooded));
        this.moons.push(new Moon("Tucked Way Inside the Tunnel", KingdomName.Wooded));
        let wooded12 = this.moons.length;
        this.moons.push(new Moon("Over the Cliff's Edge", KingdomName.Wooded));
        this.moons[wooded12].add_prereq_moon(wooded2);
        this.moons.push(new Moon("The Nut 'Round the Corner", KingdomName.Wooded));
        this.moons.push(new Moon("Climb the Cliff to Get the Nut", KingdomName.Wooded));
        this.moons.push(new Moon("The Nut in the Red Maze", KingdomName.Wooded));
        this.moons.push(new Moon("The Nut at the Dead End", KingdomName.Wooded));
        let wooded17 = this.moons.length;
        this.moons.push(new Moon("Cracked Nut on a Crumbling Tower", KingdomName.Wooded));
        this.moons[wooded17].add_prereq_moon(wooded1);
        let wooded18 = this.moons.length;
        this.moons.push(new Moon("The Nut that Grew on the Tall Fence", KingdomName.Wooded));
        this.moons[wooded18].add_prereq_moon(wooded2);
        this.moons.push(new Moon("Fire in the Cave", KingdomName.Wooded));
        let wooded20 = this.moons.length;
        this.moons.push(new Moon("Hey Out There, Captain Toad!", KingdomName.Wooded));
        this.moons[wooded20].add_prereq_moon(wooded4);
        let wooded21 = this.moons.length;
        this.moons.push(new Moon("Love in the Forest Ruins", KingdomName.Wooded));
        this.moons[wooded21].add_prereq_moon(wooded2);
        let wooded22 = this.moons.length;
        this.moons.push(new Moon("Inside a Rock in the Forest", KingdomName.Wooded));
        this.moons[wooded22].add_prereq_moon(wooded4);
        this.moons.push(new Moon("Shopping in Steam Gardens", KingdomName.Wooded));
        let wooded24 = this.moons.length;
        this.moons.push(new Moon("Nut Planted in the Tower", KingdomName.Wooded));
        this.moons[wooded24].add_prereq_moon(wooded1);
        let wooded25 = this.moons.length;
        this.moons.push(new Moon("Stretching Your Legs", KingdomName.Wooded));
        this.moons[wooded25].add_prereq_moon(wooded1);
        let wooded26 = this.moons.length;
        this.moons.push(new Moon("Spinning-Platforms Treasure", KingdomName.Wooded));
        this.moons[wooded26].add_prereq_moon(wooded2);
        let wooded27 = this.moons.length;
        this.moons.push(new Moon("Make the Secret Flower Field Bloom", KingdomName.Wooded));
        this.moons[wooded27].add_prereq_moon(wooded4);
        this.moons.push(new Moon("Rolling Rock in the Deep Woods", KingdomName.Wooded));
        this.moons.push(new Moon("Glowing in the Deep Woods", KingdomName.Wooded));
        this.moons.push(new Moon("Past the Peculiar Pipes", KingdomName.Wooded));
        let wooded31 = this.moons.length;
        this.moons.push(new Moon("By the Babbling Brook in Deep Woods", KingdomName.Wooded));
        let wooded32 = this.moons.length;
        this.moons.push(new Moon("The Hard Rock in Deep Woods", KingdomName.Wooded));
        this.moons.push(new Moon("A Treasure Made from Coins", KingdomName.Wooded));
        let wooded34 = this.moons.length;
        this.moons.push(new Moon("Beneath the Roots of the Moving Tree", KingdomName.Wooded));
        let wooded35 = this.moons.length;
        this.moons.push(new Moon("Deep Woods Treasure Trap", KingdomName.Wooded));
        let wooded36 = this.moons.length;
        this.moons.push(new Moon("Exploring for Treasure", KingdomName.Wooded));
        let wooded37 = this.moons.length;
        this.moons.push(new Moon("Wooded Kingdom Timer Challenge 1", KingdomName.Wooded));
        this.moons[wooded37].add_prereq_moon(wooded4);
        let wooded38 = this.moons.length;
        this.moons.push(new Moon("Wooded Kingdom Timer Challenge 2", KingdomName.Wooded));
        this.moons[wooded38].add_prereq_moon(wooded4);
        this.moons.push(new Moon("Flooding Pipeway", KingdomName.Wooded));
        this.moons.push(new Moon("Flooding Pipeway Ceiling Secret", KingdomName.Wooded));
        let wooded41 = this.moons.length;
        this.moons.push(new Moon("Wandering in the Fog", KingdomName.Wooded));
        this.moons[wooded41].add_prereq_moon(wooded2);
        let wooded42 = this.moons.length;
        this.moons.push(new Moon("Nut Hidden in the Fog", KingdomName.Wooded));
        this.moons[wooded42].add_prereq_moon(wooded2);
        let wooded43 = this.moons.length;
        this.moons.push(new Moon("Flower Road Run", KingdomName.Wooded));
        this.moons[wooded43].add_prereq_moon(wooded2);
        let wooded44 = this.moons.length;
        this.moons.push(new Moon("Flower Road Reach", KingdomName.Wooded));
        this.moons[wooded44].add_prereq_moon(wooded2);
        let wooded45 = this.moons.length;
        this.moons.push(new Moon("Elevator Escalation", KingdomName.Wooded));
        this.moons[wooded45].add_prereq_moon(wooded2);
        let wooded46 = this.moons.length;
        this.moons.push(new Moon("Elevator Blind Spot", KingdomName.Wooded));
        this.moons[wooded46].add_prereq_moon(wooded2);
        let wooded47 = this.moons.length;
        this.moons.push(new Moon("Walking on Clouds", KingdomName.Wooded));
        this.moons[wooded47].add_prereq_moon(wooded4);
        let wooded48 = this.moons.length;
        this.moons.push(new Moon("Above the Clouds", KingdomName.Wooded));
        this.moons[wooded48].add_prereq_moon(wooded4);
        let wooded49 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Steam Gardens!", KingdomName.Wooded));
        let wooded51 = this.moons.length;
        this.moons.push(new Moon("Swing Around Secret Flower Field", KingdomName.Wooded));
        this.moons[wooded51].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded51].add_prereq_moon(wooded4);
        let wooded52 = this.moons.length;
        this.moons.push(new Moon("Jammin' in the Wooded Kingdom", KingdomName.Wooded));
        this.moons[wooded52].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded52].add_prereq_moon(wooded4);
        let wooded53 = this.moons.length;
        this.moons.push(new Moon("Wooded Kingdom Regular Cup", KingdomName.Wooded));
        this.moons[wooded53].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded53].add_prereq_moon(wooded4);
        let wooded54 = this.moons.length;
        this.moons.push(new Moon("Peach in the Wooded Kingdom", KingdomName.Wooded));
        this.moons[wooded54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded54].add_prereq_moon(wooded4);
        let wooded55 = this.moons.length;
        this.moons.push(new Moon("High Up in the Cave", KingdomName.Wooded));
        this.moons[wooded55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded55].add_prereq_moon(wooded4);
        let wooded56 = this.moons.length;
        this.moons.push(new Moon("Lost in the Tall Trees", KingdomName.Wooded));
        this.moons[wooded56].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded56].add_prereq_moon(wooded4);
        let wooded57 = this.moons.length;
        this.moons.push(new Moon("Looking Down on the Goombas", KingdomName.Wooded));
        this.moons[wooded57].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded57].add_prereq_moon(wooded4);
        let wooded58 = this.moons.length;
        this.moons.push(new Moon("High Up on a Rock Wall", KingdomName.Wooded));
        this.moons[wooded58].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded58].add_prereq_moon(wooded4);
        let wooded59 = this.moons.length;
        this.moons.push(new Moon("The Nut in the Robot Storeroom", KingdomName.Wooded));
        this.moons[wooded59].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded59].add_prereq_moon(wooded4);
        let wooded60 = this.moons.length;
        this.moons.push(new Moon("Above the Iron Mountain Path", KingdomName.Wooded));
        this.moons[wooded60].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded60].add_prereq_moon(wooded4);
        let wooded61 = this.moons.length;
        this.moons.push(new Moon("The Nut Under the Observation Deck", KingdomName.Wooded));
        this.moons[wooded61].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded61].add_prereq_moon(wooded4);
        let wooded62 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling the Forest", KingdomName.Wooded));
        this.moons[wooded62].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded62].add_prereq_moon(wooded4);
        let wooded63 = this.moons.length;
        this.moons.push(new Moon("Invader in the Sky Garden", KingdomName.Wooded));
        this.moons[wooded63].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded63].add_prereq_moon(wooded4);
        let wooded64 = this.moons.length;
        this.moons.push(new Moon("Hot, Hot, Hot from the Campfire", KingdomName.Wooded));
        this.moons[wooded64].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded64].add_prereq_moon(wooded4);
        let wooded65 = this.moons.length;
        this.moons.push(new Moon("Wooded Kingdom Timer Challenge 3", KingdomName.Wooded));
        this.moons[wooded65].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded65].add_prereq_moon(wooded4);
        let wooded66 = this.moons.length;
        this.moons.push(new Moon("Moon Shards in the Forest", KingdomName.Wooded));
        this.moons[wooded66].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded66].add_prereq_moon(wooded4);
        let wooded67 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: On Top of the Wall", KingdomName.Wooded));
        this.moons[wooded67].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded67].add_prereq_moon(wooded4);
        let wooded68 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Stretching", KingdomName.Wooded));
        this.moons[wooded68].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded68].add_prereq_moon(wooded4);
        let wooded69 = this.moons.length;
        this.moons.push(new Moon("Wooded Kingdom Master Cup", KingdomName.Wooded));
        this.moons[wooded69].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded69].add_prereq_moon(wooded53);
        this.moons[wooded69].add_prereq_moon(wooded4);
        let wooded70 = this.moons.length;
        this.moons.push(new Moon("I Met an Uproot!", KingdomName.Wooded));
        this.moons[wooded70].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded70].add_prereq_moon(wooded4);
        let wooded71 = this.moons.length;
        this.moons.push(new Moon("Invisible Road: Danger!", KingdomName.Wooded));
        this.moons[wooded71].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded71].add_prereq_moon(wooded4);
        let wooded72 = this.moons.length;
        this.moons.push(new Moon("Invisible Road: Hidden Room", KingdomName.Wooded));
        this.moons[wooded72].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded72].add_prereq_moon(wooded4);
        let wooded73 = this.moons.length;
        this.moons.push(new Moon("Herding Sheep Above the Forest Fog", KingdomName.Wooded));
        this.moons[wooded73].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded73].add_prereq_moon(wooded4);
        let wooded74 = this.moons.length;
        this.moons.push(new Moon("Herding Sheep on the Iron Bridge", KingdomName.Wooded));
        this.moons[wooded74].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded74].add_prereq_moon(wooded4);
        let wooded75 = this.moons.length;
        this.moons.push(new Moon("Down and Back Breakdown Road", KingdomName.Wooded));
        this.moons[wooded75].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded75].add_prereq_moon(wooded4);
        let wooded76 = this.moons.length;
        this.moons.push(new Moon("Below Breakdown Road", KingdomName.Wooded));
        this.moons[wooded76].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[wooded76].add_prereq_moon(wooded4);
        let moon27 = this.moons.length;
        this.moons.push(new Moon("Found with Moon Kingdom Art", KingdomName.Wooded));
        this.moons[moon27].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[moon27].add_prereq_kingdom_count(KingdomName.Moon, 2);
        this.offset.push([wooded1, this.moons.length]);

        // cloud kingdom
        let cloud1 = this.moons.length;
        this.moons.push(new Moon("Picture Match: Basically a Goomba", KingdomName.Cloud));
        this.moons[cloud1].add_prereq_kingdom(KingdomName.Lost);
        this.moons[cloud1].add_prereq_kingdom_count(KingdomName.Cloud, 2);
        let cloud2 = this.moons.length;
        this.moons.push(new Moon("Peach in the Cloud Kingdom", KingdomName.Cloud));
        this.moons[cloud2].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud3 = this.moons.length;
        this.moons.push(new Moon("Digging in the...Cloud?", KingdomName.Cloud));
        this.moons[cloud3].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud4 = this.moons.length;
        this.moons.push(new Moon("High, High Above the Clouds", KingdomName.Cloud));
        this.moons[cloud4].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud5 = this.moons.length;
        this.moons.push(new Moon("Crossing the Cloud Sea", KingdomName.Cloud));
        this.moons[cloud5].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud6 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Up and Down", KingdomName.Cloud));
        this.moons[cloud6].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud7 = this.moons.length;
        this.moons.push(new Moon("Picture Match: A Stellar Goomba", KingdomName.Cloud));
        this.moons[cloud7].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cloud7].add_prereq_moon(cloud1);
        let cloud8 = this.moons.length;
        this.moons.push(new Moon("King of the Cube!", KingdomName.Cloud));
        this.moons[cloud8].add_prereq_kingdom(KingdomName.Mushroom);
        let cloud9 = this.moons.length;
        this.moons.push(new Moon("The Sixth Face", KingdomName.Cloud));
        this.moons[cloud9].add_prereq_kingdom(KingdomName.Mushroom);
        let dark18 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 4", KingdomName.Cloud));
        this.moons[dark18].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([cloud1, this.moons.length]);

        // lost kingdom
        let lost1 = this.moons.length;
        this.moons.push(new Moon("Atop a Propeller Pillar", KingdomName.Lost));
        this.moons.push(new Moon("Below the Cliff's Edge", KingdomName.Lost));
        this.moons.push(new Moon("Inside the Stone Cage", KingdomName.Lost));
        this.moons.push(new Moon("On a Tree in the Swamp", KingdomName.Lost));
        this.moons.push(new Moon("Over the Fuzzies, Above the Swamp", KingdomName.Lost));
        let lost6 = this.moons.length;
        this.moons.push(new Moon("Avoiding Fuzzies Inside the Wall", KingdomName.Lost));
        this.moons.push(new Moon("Inside the Rising Stone Pillar", KingdomName.Lost));
        this.moons.push(new Moon("Enjoying the View of Forgotten Isle", KingdomName.Lost));
        this.moons.push(new Moon("On the Mountain Road", KingdomName.Lost));
        this.moons.push(new Moon("A Propeller Pillar's Secret", KingdomName.Lost));
        this.moons.push(new Moon("Wrecked Rock Block", KingdomName.Lost));
        this.moons.push(new Moon("A Butterfly's Treasure", KingdomName.Lost));
        let lost13 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping in the Jungle!", KingdomName.Lost));
        this.moons[lost13].add_prereq_kingdom_count(KingdomName.Lost, 2);
        this.moons.push(new Moon("Cave Gardening", KingdomName.Lost));
        this.moons.push(new Moon("Moon Shards in the Jungle", KingdomName.Lost));
        this.moons.push(new Moon("Peeking Out from Under the Bridge", KingdomName.Lost));
        this.moons.push(new Moon("Twist 'n' Turn-Up Treasure", KingdomName.Lost));
        this.moons.push(new Moon("Soaring Over Forgotten Isle!", KingdomName.Lost));
        this.moons.push(new Moon("The Caged Gold", KingdomName.Lost));
        let lost20 = this.moons.length;
        this.moons.push(new Moon("Get Some Rest, Captain Toad", KingdomName.Lost));
        this.moons.push(new Moon("Shopping on Forgotten Isle", KingdomName.Lost));
        let lost22 = this.moons.length;
        this.moons.push(new Moon("Taxi Flying Through Forgotten Isle", KingdomName.Lost));
        this.moons[lost22].add_prereq_kingdom(KingdomName.Mushroom);
        let lost23 = this.moons.length;
        this.moons.push(new Moon("I Met a Tropical Wiggler", KingdomName.Lost));
        this.moons[lost23].add_prereq_kingdom(KingdomName.Mushroom);
        let lost24 = this.moons.length;
        this.moons.push(new Moon("Lost Kingdom Regular Cup", KingdomName.Lost));
        this.moons[lost24].add_prereq_kingdom(KingdomName.Mushroom);
        let lost25 = this.moons.length;
        this.moons.push(new Moon("Peach in the Lost Kingdom", KingdomName.Lost));
        this.moons[lost25].add_prereq_kingdom(KingdomName.Mushroom);
        let lost26 = this.moons.length;
        this.moons.push(new Moon("The Shining Fruit", KingdomName.Lost));
        this.moons[lost26].add_prereq_kingdom(KingdomName.Mushroom);
        let lost27 = this.moons.length;
        this.moons.push(new Moon("Jump Down to the Top of a Tree", KingdomName.Lost));
        this.moons[lost27].add_prereq_kingdom(KingdomName.Mushroom);
        let lost28 = this.moons.length;
        this.moons.push(new Moon("Line It Up, Blow It Up", KingdomName.Lost));
        this.moons[lost28].add_prereq_kingdom(KingdomName.Mushroom);
        let lost29 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Stretch and Shrink", KingdomName.Lost));
        this.moons[lost29].add_prereq_kingdom(KingdomName.Mushroom);
        let lost30 = this.moons.length;
        this.moons.push(new Moon("Lost Kingdom Master Cup", KingdomName.Lost));
        this.moons[lost30].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[lost30].add_prereq_moon(lost24);
        let lost31 = this.moons.length;
        this.moons.push(new Moon("Lost Kingdom Timer Challenge", KingdomName.Lost));
        this.moons[lost31].add_prereq_kingdom(KingdomName.Mushroom);
        let lost32 = this.moons.length;
        this.moons.push(new Moon("Stretch and Traverse the Jungle", KingdomName.Lost));
        this.moons[lost32].add_prereq_kingdom(KingdomName.Mushroom);
        let lost33 = this.moons.length;
        this.moons.push(new Moon("Aglow in the Jungle", KingdomName.Lost));
        this.moons[lost33].add_prereq_kingdom(KingdomName.Mushroom);
        let lost34 = this.moons.length;
        this.moons.push(new Moon("Chasing Klepto", KingdomName.Lost));
        this.moons[lost34].add_prereq_kingdom(KingdomName.Mushroom);
        let lost35 = this.moons.length;
        this.moons.push(new Moon("Extremely Hot Bath", KingdomName.Lost));
        this.moons[lost35].add_prereq_kingdom(KingdomName.Mushroom);
        let snow34 = this.moons.length;
        this.moons.push(new Moon("Found with Snow Kingdom Art", KingdomName.Lost));
        this.moons[snow34].add_prereq_kingdom(KingdomName.Snow);
        let dark21 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 7", KingdomName.Lost));
        this.moons[dark21].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([lost1, this.moons.length]);

        // metro kingdom
        let metro1 = this.moons.length;
        this.moons.push(new Moon("New Donk City's Pest Problem", KingdomName.Metro, true));
        if (!options.lakeclip) {
            this.moons[lake26].add_prereq_moon(metro1);
        }
        this.moons[wooded49].add_prereq_moon(metro1);
        let metro2 = this.moons.length;
        this.moons.push(new Moon("Drummer on Board!", KingdomName.Metro));
        this.moons[metro2].add_prereq_moon(metro1);
        let metro3 = this.moons.length;
        this.moons.push(new Moon("Guitarist on Board!", KingdomName.Metro));
        this.moons[metro3].add_prereq_moon(metro1);
        let metro4 = this.moons.length;
        this.moons.push(new Moon("Bassist on Board!", KingdomName.Metro));
        this.moons[metro4].add_prereq_moon(metro1);
        let metro5 = this.moons.length;
        this.moons.push(new Moon("Trumpeter on Board!", KingdomName.Metro));
        this.moons[metro5].add_prereq_moon(metro1);
        let metro6 = this.moons.length;
        this.moons.push(new Moon("Powering Up the Station", KingdomName.Metro));
        this.moons[metro6].add_prereq_moon(metro2);
        this.moons[metro6].add_prereq_moon(metro3);
        this.moons[metro6].add_prereq_moon(metro4);
        this.moons[metro6].add_prereq_moon(metro5);
        let metro7 = this.moons.length;
        this.moons.push(new Moon("A Traditional Festival", KingdomName.Metro, true));
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Metro).set_exit_moon(metro7);
        }
        this.moons[metro7].add_prereq_moon(metro6);
        this.moons.push(new Moon("Inside an Iron Girder", KingdomName.Metro));
        this.moons.push(new Moon("Swaying in the Breeze", KingdomName.Metro));
        this.moons.push(new Moon("Girder Sandwich", KingdomName.Metro));
        let metro11 = this.moons.length;
        this.moons.push(new Moon("Glittering Above the Pool", KingdomName.Metro));
        this.moons[metro11].add_prereq_moon(metro1);
        let metro12 = this.moons.length;
        this.moons.push(new Moon("Dizzying Heights", KingdomName.Metro));
        this.moons[metro12].add_prereq_moon(metro1);
        let metro13 = this.moons.length;
        this.moons.push(new Moon("Secret Girder Tunnel!", KingdomName.Metro));
        this.moons[metro13].add_prereq_moon(metro1);
        let metro14 = this.moons.length;
        this.moons.push(new Moon("Who Piled Garbage on This?", KingdomName.Metro));
        this.moons[metro14].add_prereq_moon(metro1);
        let metro15 = this.moons.length;
        this.moons.push(new Moon("Hidden in the Scrap", KingdomName.Metro));
        this.moons[metro15].add_prereq_moon(metro1);
        let metro16 = this.moons.length;
        this.moons.push(new Moon("Left at the Cafe?", KingdomName.Metro));
        this.moons[metro16].add_prereq_moon(metro1);
        let metro17 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping on a Building", KingdomName.Metro));
        this.moons[metro17].add_prereq_moon(metro7);
        let metro18 = this.moons.length;
        this.moons.push(new Moon("How Do They Take Out the Trash?", KingdomName.Metro));
        this.moons[metro18].add_prereq_moon(metro1);
        let metro19 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Timer Challenge 1", KingdomName.Metro));
        this.moons[metro19].add_prereq_moon(metro7);
        let metro20 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Timer Challenge 2", KingdomName.Metro));
        this.moons[metro20].add_prereq_moon(metro7);
        let metro21 = this.moons.length;
        this.moons.push(new Moon("City Gardening: Building Planter", KingdomName.Metro));
        this.moons[metro21].add_prereq_moon(metro1);
        let metro22 = this.moons.length;
        this.moons.push(new Moon("City Gardening: Plaza Planter", KingdomName.Metro));
        this.moons[metro22].add_prereq_moon(metro1);
        let metro23 = this.moons.length;
        this.moons.push(new Moon("City Gardening: Rooftop Planter", KingdomName.Metro));
        this.moons[metro23].add_prereq_moon(metro1);
        let metro24 = this.moons.length;
        this.moons.push(new Moon("How You Doin' Captain Toad?", KingdomName.Metro));
        this.moons[metro24].add_prereq_moon(metro1);
        let metro25 = this.moons.length;
        this.moons.push(new Moon("Free Parking: Rooftop Hop", KingdomName.Metro));
        this.moons[metro25].add_prereq_moon(metro1);
        let metro26 = this.moons.length;
        this.moons.push(new Moon("Bench Friends", KingdomName.Metro));
        this.moons[metro26].add_prereq_moon(metro1);
        this.moons.push(new Moon("Shopping in New Donk City", KingdomName.Metro));
        let metro28 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Slots", KingdomName.Metro));
        this.moons[metro28].add_prereq_moon(metro1);
        let metro29 = this.moons.length;
        this.moons.push(new Moon("Jump-Rope Hero", KingdomName.Metro));
        this.moons[metro29].add_prereq_moon(metro1);
        let metro30 = this.moons.length;
        this.moons.push(new Moon("Jump-Rope Genius", KingdomName.Metro));
        this.moons[metro30].add_prereq_moon(metro1);
        this.moons[metro30].add_prereq_moon(metro29);
        let metro31 = this.moons.length;
        this.moons.push(new Moon("Remotely Captured Car", KingdomName.Metro));
        this.moons[metro31].add_prereq_moon(metro1);
        let metro32 = this.moons.length;
        this.moons.push(new Moon("RC Car Pro!", KingdomName.Metro));
        this.moons[metro32].add_prereq_moon(metro31);
        let metro33 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In the Private Room", KingdomName.Metro));
        this.moons[metro33].add_prereq_moon(metro1);
        let metro34 = this.moons.length;
        this.moons.push(new Moon("City Hall Lost & Found", KingdomName.Metro));
        let metro35 = this.moons.length;
        this.moons.push(new Moon("Sewer Treasure", KingdomName.Metro));
        this.moons[metro35].add_prereq_moon(metro2);
        this.moons[metro35].add_prereq_moon(metro3);
        this.moons[metro35].add_prereq_moon(metro4);
        this.moons[metro35].add_prereq_moon(metro5);
        let metro36 = this.moons.length;
        this.moons.push(new Moon("Celebrating in the Streets!", KingdomName.Metro));
        this.moons[metro36].add_prereq_moon(metro6);
        let metro37 = this.moons.length;
        this.moons.push(new Moon("Pushing Through the Crowd", KingdomName.Metro));
        this.moons[metro37].add_prereq_moon(metro1);
        let metro38 = this.moons.length;
        this.moons.push(new Moon("High Over the Crowd", KingdomName.Metro));
        this.moons[metro38].add_prereq_moon(metro1);
        let metro39 = this.moons.length;
        this.moons.push(new Moon("Rewiring the Neighborhood", KingdomName.Metro));
        this.moons[metro39].add_prereq_moon(metro1);
        let metro40 = this.moons.length;
        this.moons.push(new Moon("Off the Beaten Wire", KingdomName.Metro));
        this.moons[metro40].add_prereq_moon(metro1);
        let metro41 = this.moons.length;
        this.moons.push(new Moon("Moon Shards Under Siege", KingdomName.Metro));
        this.moons[metro41].add_prereq_moon(metro1);
        let metro42 = this.moons.length;
        this.moons.push(new Moon("Sharpshooting Under Siege", KingdomName.Metro));
        this.moons[metro42].add_prereq_moon(metro1);
        let metro43 = this.moons.length;
        this.moons.push(new Moon("Inside the Rotating Maze", KingdomName.Metro));
        this.moons[metro43].add_prereq_moon(metro1);
        let metro44 = this.moons.length;
        this.moons.push(new Moon("Outside the Rotating Maze", KingdomName.Metro));
        this.moons[metro44].add_prereq_moon(metro1);
        let metro45 = this.moons.length;
        this.moons.push(new Moon("Hanging from a High-Rise", KingdomName.Metro));
        this.moons[metro45].add_prereq_moon(metro1);
        let metro46 = this.moons.length;
        this.moons.push(new Moon("Vaulting Up a High-Rise", KingdomName.Metro));
        this.moons[metro46].add_prereq_moon(metro1);
        let metro47 = this.moons.length;
        this.moons.push(new Moon("Bullet Billding", KingdomName.Metro));
        this.moons[metro47].add_prereq_moon(metro1);
        let metro48 = this.moons.length;
        this.moons.push(new Moon("One Man's Trash...", KingdomName.Metro));
        this.moons[metro48].add_prereq_moon(metro1);
        let metro49 = this.moons.length;
        this.moons.push(new Moon("Motor Scooter: Escape", KingdomName.Metro));
        this.moons[metro49].add_prereq_moon(metro1);
        let metro50 = this.moons.length;
        this.moons.push(new Moon("Big Jump: Escape!", KingdomName.Metro));
        this.moons[metro50].add_prereq_moon(metro1);
        let metro51 = this.moons.length;
        this.moons.push(new Moon("Secret Path to New Donk City!", KingdomName.Metro));
        this.moons[metro51].add_prereq_moon(metro1);
        this.moons[metro51].add_prereq_moon(sand10);
        this.moons[metro51].add_prereq_kingdom_count(KingdomName.Sand, 2);
        let metro52 = this.moons.length;
        this.moons.push(new Moon("A Tourist in the Metro Kingdom!", KingdomName.Metro));
        this.moons[metro52].add_prereq_moon(metro7);
        this.moons[metro52].add_prereq_moon(sand4);
        this.moons[metro53].add_prereq_moon(metro1);
        this.moons[cascade19].add_prereq_moon(metro52);
        let metro54 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling the City", KingdomName.Metro));
        this.moons[metro54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro54].add_prereq_moon(metro7);
        let metro55 = this.moons.length;
        this.moons.push(new Moon("Mario Signs His Name", KingdomName.Metro));
        this.moons[metro55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro55].add_prereq_moon(metro7);
        let metro56 = this.moons.length;
        this.moons.push(new Moon("Surprise Clown!", KingdomName.Metro));
        this.moons[metro56].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro56].add_prereq_moon(metro7);
        let metro57 = this.moons.length;
        this.moons.push(new Moon("A Request from the Mayor", KingdomName.Metro));
        this.moons[metro57].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro57].add_prereq_moon(metro7);
        let metro58 = this.moons.length;
        this.moons.push(new Moon("Jammin' in the Metro Kingdom", KingdomName.Metro));
        this.moons[metro58].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro58].add_prereq_moon(metro7);
        let metro59 = this.moons.length;
        this.moons.push(new Moon("Sphynx in the City", KingdomName.Metro));
        this.moons[metro59].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro59].add_prereq_moon(metro7);
        let metro60 = this.moons.length;
        this.moons.push(new Moon("Free Parking: Leap of Faith", KingdomName.Metro));
        this.moons[metro60].add_prereq_moon(metro7);
        this.moons[metro60].add_prereq_kingdom(KingdomName.Mushroom);
        let metro61 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Regular Cup", KingdomName.Metro));
        this.moons[metro61].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro61].add_prereq_moon(metro7);
        let metro62 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek: In the City", KingdomName.Metro));
        this.moons[metro62].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro62].add_prereq_moon(metro7);
        let metro63 = this.moons.length;
        this.moons.push(new Moon("Powering Up the Power Plant", KingdomName.Metro));
        this.moons[metro63].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro63].add_prereq_moon(metro7);
        let metro64 = this.moons.length;
        this.moons.push(new Moon("Up on the Big Screen", KingdomName.Metro));
        this.moons[metro64].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro64].add_prereq_moon(metro7);
        let metro65 = this.moons.length;
        this.moons.push(new Moon("Down Inside the Big Screen", KingdomName.Metro));
        this.moons[metro65].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro65].add_prereq_moon(metro7);
        let metro66 = this.moons.length;
        this.moons.push(new Moon("Peach in the Metro Kingdom", KingdomName.Metro));
        this.moons[metro66].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro66].add_prereq_moon(metro7);
        let metro67 = this.moons.length;
        this.moons.push(new Moon("Hanging Between Buildings", KingdomName.Metro));
        this.moons[metro67].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro67].add_prereq_moon(metro7);
        let metro68 = this.moons.length;
        this.moons.push(new Moon("Crossing Lines", KingdomName.Metro));
        this.moons[metro68].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro68].add_prereq_moon(metro7);
        let metro69 = this.moons.length;
        this.moons.push(new Moon("Out of a Crate in the City", KingdomName.Metro));
        this.moons[metro69].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro69].add_prereq_moon(metro7);
        let metro70 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling in the Park", KingdomName.Metro));
        this.moons[metro70].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro70].add_prereq_moon(metro7);
        let metro71 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Timer Challenge 3", KingdomName.Metro));
        this.moons[metro71].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro71].add_prereq_moon(metro7);
        let metro72 = this.moons.length;
        this.moons.push(new Moon("Found in the Park! Good Dog!", KingdomName.Metro));
        this.moons[metro72].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro72].add_prereq_moon(metro7);
        let metro73 = this.moons.length;
        this.moons.push(new Moon("RC Car Champ", KingdomName.Metro));
        this.moons[metro73].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro73].add_prereq_moon(metro32);
        this.moons[metro73].add_prereq_moon(metro7);
        let metro74 = this.moons.length;
        this.moons.push(new Moon("Metro Kingdom Master Cup", KingdomName.Metro));
        this.moons[metro74].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro74].add_prereq_moon(metro61);
        this.moons[metro74].add_prereq_moon(metro7);
        let metro75 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek: In the Crowd", KingdomName.Metro));
        this.moons[metro75].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro75].add_prereq_moon(metro7);
        let metro76 = this.moons.length;
        this.moons.push(new Moon("Scaling Pitchblack Mountain", KingdomName.Metro));
        this.moons[metro76].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro76].add_prereq_moon(metro7);
        let metro77 = this.moons.length;
        this.moons.push(new Moon("Reaching Pitchblack Island", KingdomName.Metro));
        this.moons[metro77].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro77].add_prereq_moon(metro7);
        let metro78 = this.moons.length;
        this.moons.push(new Moon("Swinging Scaffolding: Jump!", KingdomName.Metro));
        this.moons[metro78].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro78].add_prereq_moon(metro7);
        let metro79 = this.moons.length;
        this.moons.push(new Moon("Swinging Scaffolding: Break!", KingdomName.Metro));
        this.moons[metro79].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro79].add_prereq_moon(metro7);
        let metro80 = this.moons.length;
        this.moons.push(new Moon("Motor Scooter Daredevil!", KingdomName.Metro));
        this.moons[metro80].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro80].add_prereq_moon(metro7);
        let metro81 = this.moons.length;
        this.moons.push(new Moon("Full-Throttle Scooting!", KingdomName.Metro));
        this.moons[metro81].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[metro81].add_prereq_moon(metro7);
        let seaside50 = this.moons.length;
        this.moons.push(new Moon("Found with Seaside Kingdom Art", KingdomName.Metro));
        this.moons[seaside50].add_prereq_kingdom(KingdomName.Seaside);
        let dark16 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 2", KingdomName.Metro));
        this.moons[dark16].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([metro1, this.moons.length]);

        // snow kingdom
        let snow1 = this.moons.length;
        this.moons.push(new Moon("The Icicle Barrier", KingdomName.Snow));
        let snow2 = this.moons.length;
        this.moons.push(new Moon("The Ice Wall Barrier", KingdomName.Snow));
        let snow3 = this.moons.length;
        this.moons.push(new Moon("The Gusty Barrier", KingdomName.Snow));
        let snow4 = this.moons.length;
        this.moons.push(new Moon("The Snowy Mountain Barrier", KingdomName.Snow));
        let snow5 = this.moons.length;
        this.moons.push(new Moon("The Bound Bowl Grand Prix", KingdomName.Snow, true));
        if (!options.snowclip) {
            this.moons[snow5].add_prereq_moon(snow1);
            this.moons[snow5].add_prereq_moon(snow2);
            this.moons[snow5].add_prereq_moon(snow3);
            this.moons[snow5].add_prereq_moon(snow4);
        }
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Snow).set_exit_moon(snow5);
        }
        this.moons[cascade18].add_prereq_moon(snow5);
        if (!options.lakeclip) {
            this.moons[lake26].add_prereq_moon(snow5);
        }
        this.moons[wooded49].add_prereq_moon(snow5);
        this.moons.push(new Moon("Entrance to Shiveria", KingdomName.Snow));
        this.moons.push(new Moon("Behind Snowy Mountain", KingdomName.Snow));
        let snow8 = this.moons.length;
        this.moons.push(new Moon("Shining in the Snow in Town", KingdomName.Snow));
        let snow9 = this.moons.length;
        this.moons.push(new Moon("Atop a Blustery Arch", KingdomName.Snow));
        let snow10 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping in the Snow!", KingdomName.Snow));
        this.moons[snow10].add_prereq_moon(snow5);
        let snow11 = this.moons.length;
        this.moons.push(new Moon("The Shiverian Treasure Chest", KingdomName.Snow));
        let snow12 = this.moons.length;
        this.moons.push(new Moon("Treasure in the Ice Wall", KingdomName.Snow));
        let snow13 = this.moons.length;
        this.moons.push(new Moon("Snow Kingdom Timer Challenge 1", KingdomName.Snow));
        this.moons[snow13].add_prereq_moon(snow5);
        let snow14 = this.moons.length;
        this.moons.push(new Moon("Snow Kingdom Timer Challenge 2", KingdomName.Snow));
        this.moons[snow14].add_prereq_moon(snow5);
        let snow15 = this.moons.length;
        this.moons.push(new Moon("Moon Shards in the Snow", KingdomName.Snow));
        this.moons[snow15].add_prereq_moon(snow5);
        let snow16 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Snow Path Dash", KingdomName.Snow));
        this.moons[snow16].add_prereq_moon(snow5);
        let snow17 = this.moons.length;
        this.moons.push(new Moon("Fishing in the Glacier!", KingdomName.Snow));
        this.moons[snow17].add_prereq_moon(snow5);
        this.moons.push(new Moon("Ice-Dodging Goomba Stack", KingdomName.Snow));
        let snow19 = this.moons.length;
        this.moons.push(new Moon("Captain Toad is Chilly!", KingdomName.Snow));
        let snow20 = this.moons.length;
        this.moons.push(new Moon("I'm Not Cold!", KingdomName.Snow));
        this.moons[snow20].add_prereq_moon(snow5);
        this.moons.push(new Moon("Shopping in Shiveria", KingdomName.Snow));
        let snow22 = this.moons.length;
        this.moons.push(new Moon("Walking on Ice!", KingdomName.Snow));
        this.moons[snow22].add_prereq_moon(snow5);
        let snow23 = this.moons.length;
        this.moons.push(new Moon("Snowline Circuit Class S", KingdomName.Snow));
        this.moons[snow23].add_prereq_moon(snow5);
        let snow24 = this.moons.length;
        this.moons.push(new Moon("Dashing Over Cold Water!", KingdomName.Snow));
        this.moons[snow24].add_prereq_moon(snow5);
        let snow25 = this.moons.length;
        this.moons.push(new Moon("Dashing Above and Beyond!", KingdomName.Snow));
        this.moons[snow25].add_prereq_moon(snow5);
        let snow26 = this.moons.length;
        this.moons.push(new Moon("Jump 'n' Swim in the Freezing Water", KingdomName.Snow));
        this.moons[snow26].add_prereq_moon(snow5);
        let snow27 = this.moons.length;
        this.moons.push(new Moon("Freezing Water Near the Ceiling", KingdomName.Snow));
        this.moons[snow27].add_prereq_moon(snow5);
        let snow28 = this.moons.length;
        this.moons.push(new Moon("Blowing and Sliding", KingdomName.Snow));
        this.moons[snow28].add_prereq_moon(snow5);
        this.moons.push(new Moon("Moon Shards in the Cold Room", KingdomName.Snow));
        let snow30 = this.moons.length;
        this.moons.push(new Moon("Slip Behind the Ice", KingdomName.Snow));
        let snow31 = this.moons.length;
        this.moons.push(new Moon("Spinning Above the Clouds", KingdomName.Snow));
        this.moons[snow31].add_prereq_moon(snow5);
        let snow32 = this.moons.length;
        this.moons.push(new Moon("High-Altitude Spinning", KingdomName.Snow));
        this.moons[snow32].add_prereq_moon(snow5);
        let snow33 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Shiveria", KingdomName.Snow));
        if (!options.snowdram) {
            this.moons[snow33].add_prereq_kingdom(KingdomName.Mushroom);
            this.moons[snow33].add_prereq_kingdom_count(KingdomName.Snow, 2);
        }
        let snow35 = this.moons.length;
        this.moons.push(new Moon("Snow Kingdom Regular Cup", KingdomName.Snow));
        this.moons[snow35].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow35].add_prereq_moon(snow5);
        let snow36 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek in the Snow", KingdomName.Snow));
        this.moons[snow36].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow36].add_prereq_moon(snow5);
        let snow37 = this.moons.length;
        this.moons.push(new Moon("Peach in the Snow Kingdom", KingdomName.Snow));
        this.moons[snow37].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow37].add_prereq_moon(snow5);
        let snow38 = this.moons.length;
        this.moons.push(new Moon("Shining on High", KingdomName.Snow));
        this.moons[snow38].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow38].add_prereq_moon(snow5);
        let snow39 = this.moons.length;
        this.moons.push(new Moon("Above the Freezing Fish Pond", KingdomName.Snow));
        this.moons[snow39].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow39].add_prereq_moon(snow5);
        let snow40 = this.moons.length;
        this.moons.push(new Moon("Ice Floe Swimming", KingdomName.Snow));
        this.moons[snow40].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow40].add_prereq_moon(snow5);
        let snow41 = this.moons.length;
        this.moons.push(new Moon("Icy Jump Challenge", KingdomName.Snow));
        this.moons[snow41].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow41].add_prereq_moon(snow5);
        let snow42 = this.moons.length;
        this.moons.push(new Moon("Forgotten in the Holding Room", KingdomName.Snow));
        this.moons[snow42].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow42].add_prereq_moon(snow5);
        let snow43 = this.moons.length;
        this.moons.push(new Moon("It Popped Out of the Ice", KingdomName.Snow));
        this.moons[snow43].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow43].add_prereq_moon(snow5);
        let snow44 = this.moons.length;
        this.moons.push(new Moon("Deep in the Cold, Cold Water", KingdomName.Snow));
        this.moons[snow44].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow44].add_prereq_moon(snow5);
        let snow45 = this.moons.length;
        this.moons.push(new Moon("Water Pooling in the Crevasse", KingdomName.Snow));
        this.moons[snow45].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow45].add_prereq_moon(snow5);
        let snow46 = this.moons.length;
        this.moons.push(new Moon("Squirming Under Ice", KingdomName.Snow));
        this.moons[snow46].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow46].add_prereq_moon(snow5);
        let snow47 = this.moons.length;
        this.moons.push(new Moon("Snow Kingdom Timer Challenge 3", KingdomName.Snow));
        this.moons[snow47].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow47].add_prereq_moon(snow5);
        let snow48 = this.moons.length;
        this.moons.push(new Moon("Stacked-Up Ice Climb", KingdomName.Snow));
        this.moons[snow48].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow48].add_prereq_moon(snow5);
        let snow49 = this.moons.length;
        this.moons.push(new Moon("I Met a Snow Cheep Cheep!", KingdomName.Snow));
        this.moons[snow49].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow49].add_prereq_moon(snow5);
        let snow50 = this.moons.length;
        this.moons.push(new Moon("Even More Walking on Ice", KingdomName.Snow));
        this.moons[snow50].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow50].add_prereq_moon(snow22);
        this.moons[snow50].add_prereq_moon(snow5);
        let snow51 = this.moons.length;
        this.moons.push(new Moon("Snow Kingdom Master Cup", KingdomName.Snow));
        this.moons[snow51].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow51].add_prereq_moon(snow35);
        this.moons[snow51].add_prereq_moon(snow5);
        let snow52 = this.moons.length;
        this.moons.push(new Moon("Iceburn Circuit Class A", KingdomName.Snow));
        this.moons[snow52].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow52].add_prereq_moon(snow5);
        let snow53 = this.moons.length;
        this.moons.push(new Moon("Iceburn Circuit Class S", KingdomName.Snow));
        this.moons[snow53].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow53].add_prereq_moon(snow52);
        this.moons[snow53].add_prereq_moon(snow5);
        let snow54 = this.moons.length;
        this.moons.push(new Moon("Running the Flower Road", KingdomName.Snow));
        this.moons[snow54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow54].add_prereq_moon(snow5);
        let snow55 = this.moons.length;
        this.moons.push(new Moon("Looking Back on the Flower Road", KingdomName.Snow));
        this.moons[snow55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[snow55].add_prereq_moon(snow5);
        let dark19 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 5", KingdomName.Snow));
        this.moons[dark19].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([snow1, this.moons.length]);

        // seaside kingdom
        let seaside1 = this.moons.length;
        this.moons.push(new Moon("The Stone Pillar Seal", KingdomName.Seaside));
        let seaside2 = this.moons.length;
        this.moons.push(new Moon("The Lighthouse Seal", KingdomName.Seaside));
        let seaside3 = this.moons.length;
        this.moons.push(new Moon("The Hot Sprint Seal", KingdomName.Seaside));
        let seaside4 = this.moons.length;
        this.moons.push(new Moon("The Seal Above the Canyon", KingdomName.Seaside));
        let seaside5 = this.moons.length;
        this.moons.push(new Moon("The Glass is Half Full", KingdomName.Seaside, true));
        this.moons[seaside5].add_prereq_moon(seaside1);
        this.moons[seaside5].add_prereq_moon(seaside2);
        this.moons[seaside5].add_prereq_moon(seaside3);
        this.moons[seaside5].add_prereq_moon(seaside4);
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Seaside).set_exit_moon(seaside5);
        }
        this.moons[cascade18].add_prereq_moon(seaside5);
        if (!options.lakeclip) {
            this.moons[lake26].add_prereq_moon(seaside5);
        }
        this.moons[wooded49].add_prereq_moon(seaside5);
        this.moons.push(new Moon("On the Cliff Overlooking the Beach", KingdomName.Seaside));
        this.moons.push(new Moon("Ride the Jetstream", KingdomName.Seaside));
        let seaside8 = this.moons.length;
        this.moons.push(new Moon("Ocean-Bottom Maze: Treasure", KingdomName.Seaside));
        let seaside9 = this.moons.length;
        this.moons.push(new Moon("Ocean-Bottom Maze: Hidden Room", KingdomName.Seaside));
        this.moons.push(new Moon("Underwater Highway Tunnel", KingdomName.Seaside));
        this.moons.push(new Moon("Shh! It's a Shortcut!", KingdomName.Seaside));
        this.moons.push(new Moon("Gap in the Ocean Trench", KingdomName.Seaside));
        this.moons.push(new Moon("Slip Through the Nesting Spot", KingdomName.Seaside));
        this.moons.push(new Moon("Merci, Dorrie!", KingdomName.Seaside));
        let seaside15 = this.moons.length;
        this.moons.push(new Moon("Bonjour, Dorrie!", KingdomName.Seaside));
        this.moons[seaside15].add_prereq_moon(seaside5);
        let seaside16 = this.moons.length;
        this.moons.push(new Moon("Under a Dangerous Ceiling", KingdomName.Seaside));
        let seaside17 = this.moons.length;
        this.moons.push(new Moon("What the Waves Left Behind", KingdomName.Seaside));
        let seaside18 = this.moons.length;
        this.moons.push(new Moon("The Back Canyon: Excavate!", KingdomName.Seaside));
        let seaside19 = this.moons.length;
        this.moons.push(new Moon("Bubblaine Northern Reaches", KingdomName.Seaside));
        let seaside20 = this.moons.length;
        this.moons.push(new Moon("Wriggling on the Sandy Bottom", KingdomName.Seaside));
        let seaside21 = this.moons.length;
        this.moons.push(new Moon("Glass Palace Treasure Chest", KingdomName.Seaside));
        let seaside22 = this.moons.length;
        this.moons.push(new Moon("Treasure Trap Hidden in the Inlet", KingdomName.Seaside));
        let seaside23 = this.moons.length;
        this.moons.push(new Moon("Sea Gardening: Inlet Seed", KingdomName.Seaside));
        let seaside24 = this.moons.length;
        this.moons.push(new Moon("Sea Gardening: Canyon Seed", KingdomName.Seaside));
        let seaside25 = this.moons.length;
        this.moons.push(new Moon("Sea Gardening: Hot-Spring Seed", KingdomName.Seaside));
        this.moons.push(new Moon("Sea Gardening: Ocean Trench Seed", KingdomName.Seaside));
        let seaside27 = this.moons.length;
        this.moons.push(new Moon("Seaside Kingdom Timer Challenge 1", KingdomName.Seaside));
        let seaside28 = this.moons.length;
        this.moons.push(new Moon("Seaside Kingdom Timer Challenge 2", KingdomName.Seaside));
        this.moons[seaside28].add_prereq_moon(seaside5);
        let seaside29 = this.moons.length;
        this.moons.push(new Moon("Found on the Beach! Good Dog!", KingdomName.Seaside));
        this.moons[seaside29].add_prereq_moon(seaside5);
        this.moons.push(new Moon("Moon Shards in the Sea", KingdomName.Seaside));
        let seaside31 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Ocean Surface Dash", KingdomName.Seaside));
        let seaside32 = this.moons.length;
        this.moons.push(new Moon("Love by the Seaside", KingdomName.Seaside));
        let seaside33 = this.moons.length;
        this.moons.push(new Moon("Lighthouse Leaper", KingdomName.Seaside));
        this.moons[seaside33].add_prereq_moon(seaside5);
        let seaside34 = this.moons.length;
        this.moons.push(new Moon("Good Job, Captain Toad!", KingdomName.Seaside));
        let seaside35 = this.moons.length;
        this.moons.push(new Moon("Ocean Quiz: Good!", KingdomName.Seaside));
        this.moons.push(new Moon("Shopping in Bubblaine", KingdomName.Seaside));
        let seaside37 = this.moons.length;
        this.moons.push(new Moon("Beach Volleyball: Champ", KingdomName.Seaside));
        this.moons[seaside37].add_prereq_moon(seaside5);
        let seaside38 = this.moons.length;
        this.moons.push(new Moon("Beach Volleyball: Hero of the Beach!", KingdomName.Seaside));
        this.moons[seaside38].add_prereq_moon(seaside37);
        this.moons.push(new Moon("Looking Back in the Dark Waterway", KingdomName.Seaside));
        this.moons.push(new Moon("The Sphynx's Underwater Vault", KingdomName.Seaside));
        let seaside41 = this.moons.length;
        this.moons.push(new Moon("A Rumble on the Seaside Floor", KingdomName.Seaside));
        let seaside42 = this.moons.length;
        this.moons.push(new Moon("A Relaxing Dance", KingdomName.Seaside));
        this.moons.push(new Moon("Wading in the Cloud Sea", KingdomName.Seaside));
        this.moons.push(new Moon("Sunken Treasure in the Cloud Sea", KingdomName.Seaside));
        this.moons.push(new Moon("Fly Through the Narrow Valley", KingdomName.Seaside));
        let seaside46 = this.moons.length;
        this.moons.push(new Moon("Treasure Chest in the Narrow Valley", KingdomName.Seaside));
        this.moons.push(new Moon("Hurry and Stretch", KingdomName.Seaside));
        this.moons.push(new Moon("Stretch on the Side Path", KingdomName.Seaside));
        let seaside49 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Bubblaine!", KingdomName.Seaside));
        this.moons[seaside49].add_prereq_kingdom(KingdomName.Mushroom);
        let seaside51 = this.moons.length;
        this.moons.push(new Moon("Seaside Kingdom Regular Cup", KingdomName.Seaside));
        this.moons[seaside51].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside51].add_prereq_moon(seaside5);
        let seaside52 = this.moons.length;
        this.moons.push(new Moon("Peach in the Seaside Kingdom", KingdomName.Seaside));
        this.moons[seaside52].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside52].add_prereq_moon(seaside5);
        let seaside53 = this.moons.length;
        this.moons.push(new Moon("Above the Parasol: Catch!", KingdomName.Seaside));
        this.moons[seaside53].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside53].add_prereq_moon(seaside5);
        let seaside54 = this.moons.length;
        this.moons.push(new Moon("What Shines Inside the Glass", KingdomName.Seaside));
        this.moons[seaside54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside54].add_prereq_moon(seaside5);
        let seaside55 = this.moons.length;
        this.moons.push(new Moon("A Fine Detail on the Glass", KingdomName.Seaside));
        this.moons[seaside55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside55].add_prereq_moon(seaside5);
        let seaside56 = this.moons.length;
        this.moons.push(new Moon("Underwater Highway West: Explore!", KingdomName.Seaside));
        this.moons[seaside56].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside56].add_prereq_moon(seaside5);
        let seaside57 = this.moons.length;
        this.moons.push(new Moon("Underwater Highway East: Explore!", KingdomName.Seaside));
        this.moons[seaside57].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside57].add_prereq_moon(seaside5);
        let seaside58 = this.moons.length;
        this.moons.push(new Moon("Rapid Ascent on Hot Spring Island", KingdomName.Seaside));
        this.moons[seaside58].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside58].add_prereq_moon(seaside5);
        let seaside59 = this.moons.length;
        this.moons.push(new Moon("A Light Next to the Lighthouse", KingdomName.Seaside));
        this.moons[seaside59].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside59].add_prereq_moon(seaside5);
        let seaside60 = this.moons.length;
        this.moons.push(new Moon("The Tall Rock Shelf in the Deep Ocean", KingdomName.Seaside));
        this.moons[seaside60].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside60].add_prereq_moon(seaside5);
        let seaside61 = this.moons.length;
        this.moons.push(new Moon("At the Base of the Lighthouse", KingdomName.Seaside));
        this.moons[seaside61].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside61].add_prereq_moon(seaside5);
        let seaside62 = this.moons.length;
        this.moons.push(new Moon("Bird Traveling Over the Ocean", KingdomName.Seaside));
        this.moons[seaside62].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside62].add_prereq_moon(seaside5);
        let seaside63 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping at Glass Palace!", KingdomName.Seaside));
        this.moons[seaside63].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside63].add_prereq_moon(seaside5);
        let seaside64 = this.moons.length;
        this.moons.push(new Moon("Seaside Kingdom Timer Challenge 3", KingdomName.Seaside));
        this.moons[seaside64].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside64].add_prereq_moon(seaside5);
        let seaside65 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Ocean-Bottom Maze", KingdomName.Seaside));
        this.moons[seaside65].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside65].add_prereq_moon(seaside5);
        let seaside66 = this.moons.length;
        this.moons.push(new Moon("Taking Notes in the Sea", KingdomName.Seaside));
        this.moons[seaside66].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside66].add_prereq_moon(seaside5);
        let seaside67 = this.moons.length;
        this.moons.push(new Moon("Seaside Kingdom Master Cup", KingdomName.Seaside));
        this.moons[seaside67].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside67].add_prereq_moon(seaside51);
        this.moons[seaside67].add_prereq_moon(seaside5);
        let seaside68 = this.moons.length;
        this.moons.push(new Moon("Aim! Poke!", KingdomName.Seaside));
        this.moons[seaside68].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside68].add_prereq_moon(seaside5);
        let seaside69 = this.moons.length;
        this.moons.push(new Moon("Poke! Roll!", KingdomName.Seaside));
        this.moons[seaside69].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside69].add_prereq_moon(seaside5);
        let seaside70 = this.moons.length;
        this.moons.push(new Moon("The Spinning Maze: Search!", KingdomName.Seaside));
        this.moons[seaside70].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside70].add_prereq_moon(seaside5);
        let seaside71 = this.moons.length;
        this.moons.push(new Moon("The Spinning Maze: Open!", KingdomName.Seaside));
        this.moons[seaside71].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[seaside71].add_prereq_moon(seaside5);
        let luncheon49 = this.moons.length;
        this.moons.push(new Moon("Found with Luncheon Kingdom Art", KingdomName.Seaside));
        this.moons[luncheon49].add_prereq_kingdom(KingdomName.Luncheon);
        let dark20 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 6", KingdomName.Seaside));
        this.moons[dark20].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([seaside1, this.moons.length]);

        // luncheon kingdom
        let luncheon1 = this.moons.length;
        this.moons.push(new Moon("The Broodals Are After Some Cookin'", KingdomName.Luncheon));
        let luncheon2 = this.moons.length;
        this.moons.push(new Moon("Under the Cheese Rocks", KingdomName.Luncheon));
        this.moons[luncheon2].add_prereq_moon(luncheon1);
        let luncheon3 = this.moons.length;
        this.moons.push(new Moon("Big Pot on the Volcano: Dive In!", KingdomName.Luncheon, true));
        this.moons[luncheon3].add_prereq_moon(luncheon2);
        let luncheon4 = this.moons.length;
        this.moons.push(new Moon("Climb Up the Cascading Magma", KingdomName.Luncheon));
        this.moons[luncheon4].add_prereq_moon(luncheon3);
        let luncheon5 = this.moons.length;
        this.moons.push(new Moon("Cookatiel Showdown!", KingdomName.Luncheon, true));
        this.moons[luncheon5].add_prereq_moon(luncheon4);
        if (options.worldpeace) {
            kingdoms.kingdom(KingdomName.Luncheon).set_exit_moon(luncheon5);
        }
        this.moons.push(new Moon("Piled on the Salt", KingdomName.Luncheon));
        this.moons.push(new Moon("Lurking in the Pillar's Shadow", KingdomName.Luncheon));
        let luncheon8 = this.moons.length;
        this.moons.push(new Moon("Atop the Jutting Crag", KingdomName.Luncheon));
        this.moons[luncheon8].add_prereq_moon(luncheon3);
        let luncheon9 = this.moons.length;
        this.moons.push(new Moon("Is This an Ingredient Too?!", KingdomName.Luncheon));
        this.moons[luncheon9].add_prereq_moon(luncheon1);
        let luncheon10 = this.moons.length;
        this.moons.push(new Moon("Atop a Column in a Row", KingdomName.Luncheon));
        this.moons[luncheon10].add_prereq_moon(luncheon1);
        let luncheon11 = this.moons.length;
        this.moons.push(new Moon("Surrounded by Tall Mountains", KingdomName.Luncheon));
        this.moons[luncheon11].add_prereq_moon(luncheon2);
        let luncheon12 = this.moons.length;
        this.moons.push(new Moon("Island of Salt Floating in the Lava", KingdomName.Luncheon));
        this.moons[luncheon12].add_prereq_moon(luncheon1);
        let luncheon13 = this.moons.length;
        this.moons.push(new Moon("Overlooking a Bunch of Ingredients", KingdomName.Luncheon));
        this.moons[luncheon13].add_prereq_moon(luncheon2);
        let luncheon14 = this.moons.length;
        this.moons.push(new Moon("Light the Lantern on the Small Island", KingdomName.Luncheon));
        this.moons[luncheon14].add_prereq_moon(luncheon2);
        let luncheon15 = this.moons.length;
        this.moons.push(new Moon("Golden Turnip Recipe 1", KingdomName.Luncheon));
        this.moons[luncheon15].add_prereq_moon(luncheon1);
        let luncheon16 = this.moons.length;
        this.moons.push(new Moon("Golden Turnip Recipe 2", KingdomName.Luncheon));
        this.moons[luncheon16].add_prereq_moon(luncheon3);
        let luncheon17 = this.moons.length;
        this.moons.push(new Moon("Golden Turnip Recipe 3", KingdomName.Luncheon));
        this.moons[luncheon17].add_prereq_moon(luncheon2);
        let luncheon18 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom Timer Challenge 1", KingdomName.Luncheon));
        let luncheon19 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom Timer Challenge 2", KingdomName.Luncheon));
        this.moons[luncheon19].add_prereq_moon(luncheon2);
        let luncheon20 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom Timer Challenge 3", KingdomName.Luncheon));
        this.moons[luncheon20].add_prereq_moon(luncheon5);
        let luncheon21 = this.moons.length;
        this.moons.push(new Moon("Beneath the Rolling Vegetables", KingdomName.Luncheon));
        this.moons[luncheon21].add_prereq_moon(luncheon2);
        let luncheon22 = this.moons.length;
        this.moons.push(new Moon("All the Cracks are Fixed", KingdomName.Luncheon));
        this.moons[luncheon22].add_prereq_moon(luncheon2);
        let luncheon23 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Swimming in Magma", KingdomName.Luncheon));
        this.moons[luncheon23].add_prereq_moon(luncheon2);
        let luncheon24 = this.moons.length;
        this.moons.push(new Moon("Love Above the Lava", KingdomName.Luncheon));
        let luncheon25 = this.moons.length;
        this.moons.push(new Moon("Shopping in Mount Volbono", KingdomName.Luncheon));
        this.moons[luncheon25].add_prereq_moon(luncheon1);
        let luncheon26 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom Slots", KingdomName.Luncheon));
        this.moons[luncheon26].add_prereq_moon(luncheon1);
        let luncheon27 = this.moons.length;
        this.moons.push(new Moon("A Strong Simmer", KingdomName.Luncheon));
        this.moons[luncheon27].add_prereq_moon(luncheon1);
        let luncheon28 = this.moons.length;
        this.moons.push(new Moon("An Extreme Simmer", KingdomName.Luncheon));
        this.moons[luncheon28].add_prereq_moon(luncheon1);
        let luncheon29 = this.moons.length;
        this.moons.push(new Moon("Alcove Behind the Pillars of Magma", KingdomName.Luncheon));
        this.moons[luncheon29].add_prereq_moon(luncheon3);
        let luncheon30 = this.moons.length;
        this.moons.push(new Moon("Treasure Beneath the Cheese Rocks", KingdomName.Luncheon));
        this.moons[luncheon30].add_prereq_moon(luncheon3);
        let luncheon31 = this.moons.length;
        this.moons.push(new Moon("Light the Two Flames", KingdomName.Luncheon));
        this.moons[luncheon31].add_prereq_moon(luncheon3);
        let luncheon32 = this.moons.length;
        this.moons.push(new Moon("Light the Far-Off Lanterns", KingdomName.Luncheon));
        this.moons[luncheon32].add_prereq_moon(luncheon4);
        let luncheon33 = this.moons.length;
        this.moons.push(new Moon("Bon Appetit, Captain Toad!", KingdomName.Luncheon));
        this.moons[luncheon33].add_prereq_moon(luncheon4);
        let luncheon34 = this.moons.length;
        this.moons.push(new Moon("The Treasure Chest in the Veggies", KingdomName.Luncheon));
        this.moons[luncheon34].add_prereq_moon(luncheon3);
        let luncheon35 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping in the Volcano!", KingdomName.Luncheon));
        this.moons[luncheon35].add_prereq_moon(luncheon5);
        let luncheon36 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Big Pot Swim", KingdomName.Luncheon));
        this.moons[luncheon36].add_prereq_moon(luncheon5);
        let luncheon37 = this.moons.length;
        this.moons.push(new Moon("Magma Swamp: Floating and Sinking", KingdomName.Luncheon));
        this.moons[luncheon37].add_prereq_moon(luncheon1);
        let luncheon38 = this.moons.length;
        this.moons.push(new Moon("Corner of the Magma Swamp", KingdomName.Luncheon));
        this.moons[luncheon38].add_prereq_moon(luncheon1);
        let luncheon39 = this.moons.length;
        this.moons.push(new Moon("Magma Narrow Path", KingdomName.Luncheon));
        this.moons[luncheon39].add_prereq_moon(luncheon2);
        let luncheon40 = this.moons.length;
        this.moons.push(new Moon("Crossing to the Magma", KingdomName.Luncheon));
        this.moons[luncheon40].add_prereq_moon(luncheon2);
        let luncheon41 = this.moons.length;
        this.moons.push(new Moon("Fork Flickin' to the Summit", KingdomName.Luncheon));
        this.moons[luncheon41].add_prereq_moon(luncheon1);
        let luncheon42 = this.moons.length;
        this.moons.push(new Moon("Fork Flickin' Detour", KingdomName.Luncheon));
        this.moons[luncheon42].add_prereq_moon(luncheon1);
        let luncheon43 = this.moons.length;
        this.moons.push(new Moon("Excavate 'n' Search the Cheese Rocks", KingdomName.Luncheon));
        this.moons[luncheon43].add_prereq_moon(luncheon1);
        let luncheon44 = this.moons.length;
        this.moons.push(new Moon("Climb the Cheese Rocks", KingdomName.Luncheon));
        this.moons[luncheon44].add_prereq_moon(luncheon1);
        let luncheon45 = this.moons.length;
        this.moons.push(new Moon("Spinning Athletics End Goal", KingdomName.Luncheon));
        this.moons[luncheon45].add_prereq_moon(luncheon2);
        let luncheon46 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Spinning Athletics", KingdomName.Luncheon));
        this.moons[luncheon46].add_prereq_moon(luncheon2);
        let luncheon47 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Mount Volbono!", KingdomName.Luncheon));
        this.moons[luncheon47].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon47].add_prereq_kingdom_count(KingdomName.Lake, 2);
        this.moons[luncheon47].add_prereq_kingdom_count(KingdomName.Wooded, 2);
        this.moons[luncheon47].add_prereq_moon(wooded2);
        let luncheon48 = this.moons.length;
        this.moons.push(new Moon("A Tourist in the Luncheon Kingdom!", KingdomName.Luncheon));
        this.moons[luncheon48].add_prereq_moon(luncheon5);
        this.moons[luncheon48].add_prereq_moon(cascade19);
        this.moons[luncheon49].add_prereq_moon(luncheon5);
        let luncheon50 = this.moons.length;
        this.moons.push(new Moon("The Rooftop Lantern", KingdomName.Luncheon));
        this.moons[luncheon50].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon50].add_prereq_moon(luncheon5);
        let luncheon51 = this.moons.length;
        this.moons.push(new Moon("Jammin' in the Luncheon Kingdom", KingdomName.Luncheon));
        this.moons[luncheon51].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon51].add_prereq_moon(luncheon5);
        let luncheon52 = this.moons.length;
        this.moons.push(new Moon("Mechanic: Repairs Complete!", KingdomName.Luncheon));
        this.moons[luncheon52].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon52].add_prereq_moon(luncheon5);
        let luncheon53 = this.moons.length;
        this.moons.push(new Moon("Diving from the Big Pot!", KingdomName.Luncheon));
        this.moons[luncheon53].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon53].add_prereq_moon(luncheon5);
        let luncheon54 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek: Among the Food", KingdomName.Luncheon));
        this.moons[luncheon54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon54].add_prereq_moon(luncheon5);
        let luncheon55 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom: Regular Cup", KingdomName.Luncheon));
        this.moons[luncheon55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon55].add_prereq_moon(luncheon5);
        let luncheon56 = this.moons.length;
        this.moons.push(new Moon("Peach in the Luncheon Kingdom", KingdomName.Luncheon));
        this.moons[luncheon56].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon56].add_prereq_moon(luncheon5);
        let luncheon57 = this.moons.length;
        this.moons.push(new Moon("From Inside a Bright Stone", KingdomName.Luncheon));
        this.moons[luncheon57].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon57].add_prereq_moon(luncheon5);
        let luncheon58 = this.moons.length;
        this.moons.push(new Moon("Under the Meat Plateau", KingdomName.Luncheon));
        this.moons[luncheon58].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon58].add_prereq_moon(luncheon5);
        let luncheon59 = this.moons.length;
        this.moons.push(new Moon("On Top of a Tall, Tall Roof", KingdomName.Luncheon));
        this.moons[luncheon59].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon59].add_prereq_moon(luncheon5);
        let luncheon60 = this.moons.length;
        this.moons.push(new Moon("From a Crack in the Hard Ground", KingdomName.Luncheon));
        this.moons[luncheon60].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon60].add_prereq_moon(luncheon5);
        let luncheon61 = this.moons.length;
        this.moons.push(new Moon("By the Cannon Pointed at the Big Pot", KingdomName.Luncheon));
        this.moons[luncheon61].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon61].add_prereq_moon(luncheon5);
        let luncheon62 = this.moons.length;
        this.moons.push(new Moon("Luncheon Kingdom: Master Cup", KingdomName.Luncheon));
        this.moons[luncheon62].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon62].add_prereq_moon(luncheon55);
        this.moons[luncheon62].add_prereq_moon(luncheon5);
        let luncheon63 = this.moons.length;
        this.moons.push(new Moon("Stepping Over the Gears", KingdomName.Luncheon));
        this.moons[luncheon63].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon63].add_prereq_moon(luncheon5);
        let luncheon64 = this.moons.length;
        this.moons.push(new Moon("Lanterns on the Gear Steps", KingdomName.Luncheon));
        this.moons[luncheon64].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon64].add_prereq_moon(luncheon5);
        let luncheon65 = this.moons.length;
        this.moons.push(new Moon("Volcano Cave Cruisin'", KingdomName.Luncheon));
        this.moons[luncheon65].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon65].add_prereq_moon(luncheon5);
        let luncheon66 = this.moons.length;
        this.moons.push(new Moon("Volcano Cave and Mysterious Clouds", KingdomName.Luncheon));
        this.moons[luncheon66].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon66].add_prereq_moon(luncheon5);
        let luncheon67 = this.moons.length;
        this.moons.push(new Moon("Treasure of the Lava Islands", KingdomName.Luncheon));
        this.moons[luncheon67].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon67].add_prereq_moon(luncheon5);
        let luncheon68 = this.moons.length;
        this.moons.push(new Moon("Flying Over the Lava Islands", KingdomName.Luncheon));
        this.moons[luncheon68].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[luncheon68].add_prereq_moon(luncheon5);
        let dark22 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 8", KingdomName.Luncheon));
        this.moons[dark22].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([luncheon1, this.moons.length]);

        // ruined kingdom
        let ruined1 = this.moons.length;
        this.moons.push(new Moon("Battle with the Lord of Lightning!", KingdomName.Ruined, true));
        kingdoms.kingdom(KingdomName.Ruined).set_exit_moon(ruined1);
        let ruined2 = this.moons.length;
        this.moons.push(new Moon("In the Ancient Treasure Chest", KingdomName.Ruined));
        let ruined3 = this.moons.length;
        this.moons.push(new Moon("Roulette Tower: Climbed", KingdomName.Ruined));
        this.moons[ruined3].add_prereq_moon(ruined1);
        let ruined4 = this.moons.length;
        this.moons.push(new Moon("Roulette Tower: Stopped", KingdomName.Ruined));
        this.moons[ruined4].add_prereq_moon(ruined1);
        let ruined5 = this.moons.length;
        this.moons.push(new Moon("Peach in the Ruined Kingdom", KingdomName.Ruined));
        this.moons[ruined5].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined5].add_prereq_moon(ruined1);
        let ruined6 = this.moons.length;
        this.moons.push(new Moon("Caught on a Big Horn", KingdomName.Ruined));
        this.moons[ruined6].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined6].add_prereq_moon(ruined1);
        let ruined7 = this.moons.length;
        this.moons.push(new Moon("Upon the Broken Arch", KingdomName.Ruined));
        this.moons[ruined7].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined7].add_prereq_moon(ruined1);
        let ruined8 = this.moons.length;
        this.moons.push(new Moon("Rolling Rock on the Battlefield", KingdomName.Ruined));
        this.moons[ruined8].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined8].add_prereq_moon(ruined1);
        let ruined9 = this.moons.length;
        this.moons.push(new Moon("Charging Through an Army", KingdomName.Ruined));
        this.moons[ruined9].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined9].add_prereq_moon(ruined1);
        let ruined10 = this.moons.length;
        this.moons.push(new Moon("The Mummy Army's Curse", KingdomName.Ruined));
        this.moons[ruined10].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[ruined10].add_prereq_moon(ruined1);
        let dark24 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 10", KingdomName.Ruined));
        this.moons[dark24].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([ruined1, this.moons.length]);

        // bowser's kingdom
        let bowser1 = this.moons.length;
        this.moons.push(new Moon("Infiltrate Bowser's Castle", KingdomName.Bowser));
        let bowser2 = this.moons.length;
        this.moons.push(new Moon("Smart Bombing", KingdomName.Bowser));
        this.moons[bowser2].add_prereq_moon(bowser1);
        let bowser3 = this.moons.length;
        this.moons.push(new Moon("Big Broodal Battle", KingdomName.Bowser));
        this.moons[bowser3].add_prereq_moon(bowser2);
        let bowser4 = this.moons.length;
        this.moons.push(new Moon("Showdown at Bowser's Castle", KingdomName.Bowser, true));
        kingdoms.kingdom(KingdomName.Bowser).set_exit_moon(bowser4);
        this.moons[bowser4].add_prereq_moon(bowser3);
        this.moons.push(new Moon("Behind the Big Wall", KingdomName.Bowser));
        let bowser6 = this.moons.length;
        this.moons.push(new Moon("Treasure Inside the Turret", KingdomName.Bowser));
        this.moons[bowser6].add_prereq_moon(bowser1);
        let bowser7 = this.moons.length;
        this.moons.push(new Moon("From the Side Above the Castle Gate", KingdomName.Bowser));
        this.moons[bowser7].add_prereq_moon(bowser2);
        let bowser8 = this.moons.length;
        this.moons.push(new Moon("Sunken Treasure in the Moat", KingdomName.Bowser));
        this.moons[bowser8].add_prereq_moon(bowser3);
        let bowser9 = this.moons.length;
        this.moons.push(new Moon("Past the Moving Wall", KingdomName.Bowser));
        this.moons[bowser9].add_prereq_moon(bowser4);
        let bowser10 = this.moons.length;
        this.moons.push(new Moon("Above the Poison Swamp", KingdomName.Bowser));
        this.moons[bowser10].add_prereq_moon(bowser4);
        let bowser11 = this.moons.length;
        this.moons.push(new Moon("Knocking Down the Nice Frame", KingdomName.Bowser));
        this.moons[bowser11].add_prereq_moon(bowser4);
        let bowser12 = this.moons.length;
        this.moons.push(new Moon("Caught on the Iron Fence", KingdomName.Bowser));
        let bowser13 = this.moons.length;
        this.moons.push(new Moon("On the Giant Bowser Statue's Nose", KingdomName.Bowser));
        this.moons[bowser13].add_prereq_moon(bowser3);
        let bowser14 = this.moons.length;
        this.moons.push(new Moon("Inside a Block in the Castle", KingdomName.Bowser));
        this.moons[bowser14].add_prereq_moon(bowser3);
        let bowser15 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping at Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser15].add_prereq_moon(bowser4);
        let bowser16 = this.moons.length;
        this.moons.push(new Moon("Exterminate the Ogres!", KingdomName.Bowser));
        this.moons[bowser16].add_prereq_moon(bowser2);
        let bowser17 = this.moons.length;
        this.moons.push(new Moon("Bowser's Kingdom Timer Challenge 1", KingdomName.Bowser));
        this.moons[bowser17].add_prereq_moon(bowser4);
        let bowser18 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Between Spinies", KingdomName.Bowser));
        this.moons.push(new Moon("Stack Up Above the Wall", KingdomName.Bowser));
        let bowser20 = this.moons.length;
        this.moons.push(new Moon("Hidden Corridor Under the Floor", KingdomName.Bowser));
        this.moons[bowser20].add_prereq_moon(bowser3);
        let bowser21 = this.moons.length;
        this.moons.push(new Moon("Poking Your Nose in the Plaster Wall", KingdomName.Bowser));
        this.moons[bowser21].add_prereq_moon(bowser1);
        let bowser22 = this.moons.length;
        this.moons.push(new Moon("Poking the Turret Wall", KingdomName.Bowser));
        this.moons[bowser22].add_prereq_moon(bowser1);
        let bowser23 = this.moons.length;
        this.moons.push(new Moon("Poking Your Nose by the Great Gate", KingdomName.Bowser));
        this.moons[bowser23].add_prereq_moon(bowser3);
        let bowser24 = this.moons.length;
        this.moons.push(new Moon("Jizo All in a Row", KingdomName.Bowser));
        this.moons[bowser24].add_prereq_moon(bowser2);
        let bowser25 = this.moons.length;
        this.moons.push(new Moon("Underground Jizo", KingdomName.Bowser));
        this.moons[bowser25].add_prereq_moon(bowser2);
        let bowser26 = this.moons.length;
        this.moons.push(new Moon("Found Behind Bars!", KingdomName.Bowser));
        this.moons[bowser26].add_prereq_moon(bowser3);
        let bowser27 = this.moons.length;
        this.moons.push(new Moon("Fishing(?) in Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser27].add_prereq_moon(bowser4);
        let bowser28 = this.moons.length;
        this.moons.push(new Moon("Good to See You, Captain Toad!", KingdomName.Bowser));
        this.moons[bowser28].add_prereq_moon(bowser3);
        let bowser29 = this.moons.length;
        this.moons.push(new Moon("Shopping at Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser29].add_prereq_moon(bowser1);
        let bowser30 = this.moons.length;
        this.moons.push(new Moon("Bowser's Castle Treasure Vault", KingdomName.Bowser));
        this.moons[bowser30].add_prereq_moon(bowser4);
        let bowser31 = this.moons.length;
        this.moons.push(new Moon("Scene of Crossing the Poison Swamp", KingdomName.Bowser));
        this.moons[bowser31].add_prereq_moon(bowser1);
        let bowser32 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In the Folding Screen", KingdomName.Bowser));
        this.moons[bowser32].add_prereq_moon(bowser1);
        let bowser33 = this.moons.length;
        this.moons.push(new Moon("On Top of the Spinning Tower", KingdomName.Bowser));
        this.moons[bowser33].add_prereq_moon(bowser4);
        let bowser34 = this.moons.length;
        this.moons.push(new Moon("Down and Up the Spinning Tower", KingdomName.Bowser));
        this.moons[bowser34].add_prereq_moon(bowser4);
        let bowser35 = this.moons.length;
        this.moons.push(new Moon("Jizo's Big Adventure", KingdomName.Bowser));
        this.moons[bowser35].add_prereq_moon(bowser4);
        let bowser36 = this.moons.length;
        this.moons.push(new Moon("Jizo and the Hidden Room", KingdomName.Bowser));
        this.moons[bowser36].add_prereq_moon(bowser4);
        let bowser37 = this.moons.length;
        this.moons.push(new Moon("Dashing Above the Clouds", KingdomName.Bowser));
        this.moons[bowser37].add_prereq_moon(bowser3);
        let bowser38 = this.moons.length;
        this.moons.push(new Moon("Dashing Through the Clouds", KingdomName.Bowser));
        this.moons[bowser38].add_prereq_moon(bowser3);
        let bowser39 = this.moons.length;
        this.moons.push(new Moon("Sphynx Over Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser39].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser39].add_prereq_moon(bowser4);
        let bowser40 = this.moons.length;
        this.moons.push(new Moon("I Met a Pokio!", KingdomName.Bowser));
        this.moons[bowser40].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser40].add_prereq_moon(bowser4);
        let bowser41 = this.moons.length;
        this.moons.push(new Moon("Bowser's Kingdom Regular Cup", KingdomName.Bowser));
        this.moons[bowser41].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser41].add_prereq_moon(bowser4);
        let bowser42 = this.moons.length;
        this.moons.push(new Moon("A Rumble Under the Arena Floor", KingdomName.Bowser));
        this.moons[bowser42].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser42].add_prereq_moon(bowser4);
        let bowser43 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser43].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser43].add_prereq_kingdom_count(KingdomName.Cascade, 2);
        let bowser44 = this.moons.length;
        this.moons.push(new Moon("Peach in Bowser's Kingdom", KingdomName.Bowser));
        this.moons[bowser44].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser44].add_prereq_moon(bowser4);
        let bowser46 = this.moons.length;
        this.moons.push(new Moon("Behind the Tall Wall: Poke, Poke!", KingdomName.Bowser));
        this.moons[bowser46].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser46].add_prereq_moon(bowser4);
        let bowser47 = this.moons.length;
        this.moons.push(new Moon("From Crates in the Moat", KingdomName.Bowser));
        this.moons[bowser47].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser47].add_prereq_moon(bowser4);
        let bowser48 = this.moons.length;
        this.moons.push(new Moon("Caught on the Giant Horn", KingdomName.Bowser));
        this.moons[bowser48].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser48].add_prereq_moon(bowser4);
        let bowser49 = this.moons.length;
        this.moons.push(new Moon("Inside a Block at the Gate", KingdomName.Bowser));
        this.moons[bowser49].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser49].add_prereq_moon(bowser4);
        let bowser50 = this.moons.length;
        this.moons.push(new Moon("Small Bird in Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser50].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser50].add_prereq_moon(bowser4);
        let bowser51 = this.moons.length;
        this.moons.push(new Moon("Invader in Bowser's Castle", KingdomName.Bowser));
        this.moons[bowser51].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser51].add_prereq_moon(bowser4);
        let bowser52 = this.moons.length;
        this.moons.push(new Moon("Jumping from Flag to Flag", KingdomName.Bowser));
        this.moons[bowser52].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser52].add_prereq_moon(bowser4);
        let bowser53 = this.moons.length;
        this.moons.push(new Moon("Bowser's Kingdom Timer Challenge 2", KingdomName.Bowser));
        this.moons[bowser53].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser53].add_prereq_moon(bowser4);
        let bowser54 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: On the Wall", KingdomName.Bowser));
        this.moons[bowser54].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser54].add_prereq_moon(bowser4);
        let bowser55 = this.moons.length;
        this.moons.push(new Moon("Taking Notes with a Spinning Throw", KingdomName.Bowser));
        this.moons[bowser55].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser55].add_prereq_moon(bowser4);
        let bowser56 = this.moons.length;
        this.moons.push(new Moon("Third Courtyard Outskirts", KingdomName.Bowser));
        this.moons[bowser56].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser56].add_prereq_moon(bowser4);
        let bowser57 = this.moons.length;
        this.moons.push(new Moon("Stone Wall Circuit", KingdomName.Bowser));
        this.moons[bowser57].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser57].add_prereq_moon(bowser4);
        let bowser58 = this.moons.length;
        this.moons.push(new Moon("Bowser's Kingdom Master Cup", KingdomName.Bowser));
        this.moons[bowser58].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser58].add_prereq_moon(bowser41);
        this.moons[bowser58].add_prereq_moon(bowser4);
        let bowser59 = this.moons.length;
        this.moons.push(new Moon("Searching Hexagon Tower", KingdomName.Bowser));
        this.moons[bowser59].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser59].add_prereq_moon(bowser4);
        let bowser60 = this.moons.length;
        this.moons.push(new Moon("Center of Hexagon Tower", KingdomName.Bowser));
        this.moons[bowser60].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser60].add_prereq_moon(bowser4);
        let bowser61 = this.moons.length;
        this.moons.push(new Moon("Climb the Wooden Tower", KingdomName.Bowser));
        this.moons[bowser61].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser61].add_prereq_moon(bowser4);
        let bowser62 = this.moons.length;
        this.moons.push(new Moon("Poke the Wooden Tower", KingdomName.Bowser));
        this.moons[bowser62].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[bowser62].add_prereq_moon(bowser4);
        let sand63 = this.moons.length;
        this.moons.push(new Moon("Found with Sand Kingdom Art", KingdomName.Bowser));
        this.moons[sand63].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[sand63].add_prereq_kingdom_count(KingdomName.Sand, 2);
        this.offset.push([bowser1, this.moons.length]);

        // moon kingdom
        let moon1 = this.moons.length;
        this.moons.push(new Moon("Shining Above the Moon", KingdomName.Moon));
        this.moons.push(new Moon("Along the Cliff Face", KingdomName.Moon));
        let moon3 = this.moons.length;
        this.moons.push(new Moon("The Tip of a White Spire", KingdomName.Moon));
        this.moons.push(new Moon("Rolling Rock on the Moon", KingdomName.Moon));
        let moon5 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping on the Moon!", KingdomName.Moon));
        let moon6 = this.moons.length;
        this.moons.push(new Moon("Cliffside Treasure Chest", KingdomName.Moon));
        let moon7 = this.moons.length;
        this.moons.push(new Moon("Moon Kingdom Timer Challenge 1", KingdomName.Moon));
        let moon8 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: On the Moon's Surface", KingdomName.Moon));
        this.moons.push(new Moon("Under the Bowser Statue", KingdomName.Moon));
        this.moons.push(new Moon("In a Hole in the Magma", KingdomName.Moon));
        this.moons.push(new Moon("Around the Barrier Wall", KingdomName.Moon));
        this.moons.push(new Moon("On Top of the Cannon", KingdomName.Moon));
        let moon13 = this.moons.length;
        this.moons.push(new Moon("Fly to the Treasure Chest and Back", KingdomName.Moon));
        this.moons.push(new Moon("Up in the Rafters", KingdomName.Moon));
        let moon15 = this.moons.length;
        this.moons.push(new Moon("Sneaking Around in the Crater", KingdomName.Moon));
        this.moons[moon15].add_prereq_kingdom(KingdomName.Mushroom);
        let moon16 = this.moons.length;
        this.moons.push(new Moon("Found on the Moon, Good Dog!", KingdomName.Moon));
        this.moons[moon16].add_prereq_kingdom(KingdomName.Mushroom);
        let moon17 = this.moons.length;
        this.moons.push(new Moon("Moon Shards on the Moon", KingdomName.Moon));
        this.moons[moon17].add_prereq_kingdom(KingdomName.Mushroom);
        let moon18 = this.moons.length;
        this.moons.push(new Moon("Moon Quiz: Amazing!", KingdomName.Moon));
        this.moons[moon18].add_prereq_kingdom(KingdomName.Mushroom);
        let moon19 = this.moons.length;
        this.moons.push(new Moon("Thanks, Captain Toad!", KingdomName.Moon));
        this.moons[moon19].add_prereq_kingdom(KingdomName.Mushroom);
        let moon20 = this.moons.length;
        this.moons.push(new Moon("Shopping in Honeylune Ridge", KingdomName.Moon));
        this.moons[moon20].add_prereq_kingdom(KingdomName.Mushroom);
        let moon21 = this.moons.length;
        this.moons.push(new Moon("Walking on the Moon!", KingdomName.Moon));
        this.moons[moon21].add_prereq_kingdom(KingdomName.Mushroom);
        let moon22 = this.moons.length;
        this.moons.push(new Moon("Moon Kingdom Regular Cup!", KingdomName.Moon));
        this.moons[moon22].add_prereq_kingdom(KingdomName.Mushroom);
        let moon23 = this.moons.length;
        this.moons.push(new Moon("Doctor in the House", KingdomName.Moon));
        this.moons[moon23].add_prereq_kingdom(KingdomName.Mushroom);
        let moon24 = this.moons.length;
        this.moons.push(new Moon("Sphynx's Hidden Vault", KingdomName.Moon));
        this.moons[moon24].add_prereq_kingdom(KingdomName.Mushroom);
        let moon25 = this.moons.length;
        this.moons.push(new Moon("A Tourist in the Moon Kingdom!", KingdomName.Moon));
        this.moons[moon25].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[moon25].add_prereq_moon(luncheon48);
        let moon26 = this.moons.length;
        this.moons.push(new Moon("Peach in the Moon Kingdom", KingdomName.Moon));
        this.moons[moon26].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[moon26].add_prereq_moon(cap16);
        this.moons[moon26].add_prereq_moon(cascade21);
        this.moons[moon26].add_prereq_moon(sand69);
        this.moons[moon26].add_prereq_moon(lake33);
        this.moons[moon26].add_prereq_moon(wooded54);
        this.moons[moon26].add_prereq_moon(cloud2);
        this.moons[moon26].add_prereq_moon(lost25);
        this.moons[moon26].add_prereq_moon(metro66);
        this.moons[moon26].add_prereq_moon(snow37);
        this.moons[moon26].add_prereq_moon(seaside52);
        this.moons[moon26].add_prereq_moon(luncheon56);
        this.moons[moon26].add_prereq_moon(ruined5);
        this.moons[moon26].add_prereq_moon(bowser44);
        let moon28 = this.moons.length;
        this.moons.push(new Moon("Mysterious Flying Object", KingdomName.Moon));
        this.moons[moon28].add_prereq_kingdom(KingdomName.Mushroom);
        let moon29 = this.moons.length;
        this.moons.push(new Moon("Hidden on the Side of the Cliff", KingdomName.Moon));
        this.moons[moon29].add_prereq_kingdom(KingdomName.Mushroom);
        let moon30 = this.moons.length;
        this.moons.push(new Moon("Jumping High as a Frog", KingdomName.Moon));
        this.moons[moon30].add_prereq_kingdom(KingdomName.Mushroom);
        let moon31 = this.moons.length;
        this.moons.push(new Moon("Moon Kingdom Timer Challenge 2", KingdomName.Moon));
        this.moons[moon31].add_prereq_kingdom(KingdomName.Mushroom);
        let moon32 = this.moons.length;
        this.moons.push(new Moon("Walking on the Moon: Again!", KingdomName.Moon));
        this.moons[moon32].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[moon32].add_prereq_moon(moon21);
        let moon33 = this.moons.length;
        this.moons.push(new Moon("Moon Kingdom Master Cup", KingdomName.Moon));
        this.moons[moon33].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[moon33].add_prereq_moon(moon22);
        let moon34 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: In Low Gravity", KingdomName.Moon));
        this.moons[moon34].add_prereq_kingdom(KingdomName.Mushroom);
        let moon35 = this.moons.length;
        this.moons.push(new Moon("Center of the Galaxy", KingdomName.Moon));
        this.moons[moon35].add_prereq_kingdom(KingdomName.Mushroom);
        let moon36 = this.moons.length;
        this.moons.push(new Moon("Edge of the Galaxy", KingdomName.Moon));
        this.moons[moon36].add_prereq_kingdom(KingdomName.Mushroom);
        let moon37 = this.moons.length;
        this.moons.push(new Moon("Navigating Giant Swings", KingdomName.Moon));
        this.moons[moon37].add_prereq_kingdom(KingdomName.Mushroom);
        let moon38 = this.moons.length;
        this.moons.push(new Moon("A Swing on Top of a Swing", KingdomName.Moon));
        this.moons[moon38].add_prereq_kingdom(KingdomName.Mushroom);
        let cap17 = this.moons.length;
        this.moons.push(new Moon("Found with Cap Kingdom Art", KingdomName.Moon));
        this.moons[cap17].add_prereq_kingdom(KingdomName.Mushroom);
        this.moons[cap17].add_prereq_kingdom_count(KingdomName.Cap, 2);
        this.offset.push([moon1, this.moons.length]);

        // mushroom
        let mushroom1 = this.moons.length;
        this.moons.push(new Moon("Perched on the Castle Roof", KingdomName.Mushroom));
        let mushroom2 = this.moons.length;
        this.moons.push(new Moon("Pops Out of the Tail", KingdomName.Mushroom));
        let mushroom3 = this.moons.length;
        this.moons.push(new Moon("Caught Hopping at Peach's Castle!", KingdomName.Mushroom));
        this.moons.push(new Moon("Gardening for Toad: Garden Seed", KingdomName.Mushroom));
        this.moons.push(new Moon("Gardening for Toad: Field Seed", KingdomName.Mushroom));
        this.moons.push(new Moon("Gardening for Toad: Pasture Seed", KingdomName.Mushroom));
        this.moons.push(new Moon("Gardening for Toad: Lake Seed", KingdomName.Mushroom));
        this.moons.push(new Moon("Grow a Flower Garden", KingdomName.Mushroom));
        let mushroom9 = this.moons.length;
        this.moons.push(new Moon("Mushroom Kingdom Timer Challenge", KingdomName.Mushroom));
        this.moons.push(new Moon("Found at Peach's Castle! Good Dog!", KingdomName.Mushroom));
        let mushroom11 = this.moons.length;
        this.moons.push(new Moon("Taking Notes: Around the Well", KingdomName.Mushroom));
        let mushroom12 = this.moons.length;
        this.moons.push(new Moon("Herding Sheep at Peach's Castle", KingdomName.Mushroom));
        let mushroom13 = this.moons.length;
        this.moons.push(new Moon("Gobbling Fruit with Yoshi", KingdomName.Mushroom));
        let mushroom14 = this.moons.length;
        this.moons.push(new Moon("Yoshi's Second Helping!", KingdomName.Mushroom));
        this.moons[mushroom14].add_prereq_moon(mushroom13);
        let mushroom15 = this.moons.length;
        this.moons.push(new Moon("Yoshi's All Filled Up!", KingdomName.Mushroom));
        this.moons[mushroom15].add_prereq_moon(mushroom14);
        let mushroom16 = this.moons.length;
        this.moons.push(new Moon("Love at Peach's Castle", KingdomName.Mushroom));
        this.moons.push(new Moon("Toad Defender", KingdomName.Mushroom));
        this.moons.push(new Moon("Forever Onward, Captain Toad!", KingdomName.Mushroom));
        let mushroom19 = this.moons.length;
        this.moons.push(new Moon("Jammin' in the Mushroom Kingdom", KingdomName.Mushroom));
        this.moons.push(new Moon("Shopping Near Peach's Kingdom", KingdomName.Mushroom));
        let mushroom21 = this.moons.length;
        this.moons.push(new Moon("Mushroom Kingdom Regular Cup", KingdomName.Mushroom));
        let mushroom22 = this.moons.length;
        this.moons.push(new Moon("Mushroom Kingdom Master Cup", KingdomName.Mushroom));
        this.moons[mushroom22].add_prereq_moon(mushroom21);
        let mushroom23 = this.moons.length;
        this.moons.push(new Moon("Picture Match: Basically a Mario", KingdomName.Mushroom));
        let mushroom24 = this.moons.length;
        this.moons.push(new Moon("Picture Match: A Stellar Mario!", KingdomName.Mushroom));
        this.moons[mushroom24].add_prereq_moon(mushroom23);
        this.moons.push(new Moon("Light from the Ceiling", KingdomName.Mushroom));
        this.moons.push(new Moon("Loose-Tile Trackdown", KingdomName.Mushroom));
        let mushroom27 = this.moons.length;
        this.moons.push(new Moon("Totally Classic", KingdomName.Mushroom));
        let mushroom28 = this.moons.length;
        this.moons.push(new Moon("Courtyard Chest Trap", KingdomName.Mushroom));
        this.moons.push(new Moon("Yoshi's Feast in the Sea of Clouds", KingdomName.Mushroom));
        this.moons.push(new Moon("Sunken Star in the Sea of Clouds", KingdomName.Mushroom));
        this.moons.push(new Moon("Secret 2D Treasure", KingdomName.Mushroom));
        this.moons.push(new Moon("2D Boost from Bullet Bill", KingdomName.Mushroom));
        this.moons.push(new Moon("Tussle in Tostarena: Rematch", KingdomName.Mushroom, true));
        this.moons.push(new Moon("Struggle in Steam Gardens: Rematch", KingdomName.Mushroom, true));
        this.moons.push(new Moon("Dust-Up in New Donk City: Rematch", KingdomName.Mushroom, true));
        this.moons.push(new Moon("Battle in Bubblaine: Rematch", KingdomName.Mushroom, true));
        this.moons.push(new Moon("Blowup in Mount Volbano: Rematch", KingdomName.Mushroom, true));
        this.moons.push(new Moon("Rumble in Crumbleden: Rematch", KingdomName.Mushroom, true));
        let mushroom39 = this.moons.length;
        this.moons.push(new Moon("Secret Path to Peach's Castle!", KingdomName.Mushroom));
        this.moons[mushroom39].add_prereq_moon(luncheon2);
        this.moons[mushroom39].add_prereq_kingdom_count(KingdomName.Luncheon, 2);
        let mushroom40 = this.moons.length;
        this.moons.push(new Moon("A Tourist in the Mushroom Kingdom", KingdomName.Mushroom));
        this.moons[mushroom40].add_prereq_moon(moon25);
        this.moons[sand68].add_prereq_moon(mushroom40);
        let mushroom42 = this.moons.length;
        this.moons.push(new Moon("Hat-and-Seek: Mushroom Kingdom", KingdomName.Mushroom));
        this.moons[mushroom42].add_prereq_moon(moon26);
        let mushroom43 = this.moons.length;
        this.moons.push(new Moon("Princess Peach, Home Again!", KingdomName.Mushroom));
        this.moons[mushroom43].add_prereq_moon(moon26);
        let mushroom44 = this.moons.length;
        this.moons.push(new Moon("Rescue Princess Peach", KingdomName.Mushroom));
        let mushroom45 = this.moons.length;
        this.moons.push(new Moon("Achieve World Peace", KingdomName.Mushroom));
        this.moons[mushroom45].add_prereq_moon(mushroom44);
        this.moons[mushroom45].add_prereq_moon(cascade2);
        this.moons[mushroom45].add_prereq_moon(sand4);
        this.moons[mushroom45].add_prereq_moon(lake1);
        this.moons[mushroom45].add_prereq_moon(wooded4);
        this.moons[mushroom45].add_prereq_moon(metro7);
        this.moons[mushroom45].add_prereq_moon(snow5);
        this.moons[mushroom45].add_prereq_moon(seaside5);
        this.moons[mushroom45].add_prereq_moon(luncheon5);
        this.moons[mushroom45].add_prereq_moon(ruined1);
        this.moons[mushroom45].add_prereq_moon(bowser4);
        let mushroom46 = this.moons.length;
        this.moons.push(new Moon("Power Moon Knight", KingdomName.Mushroom));
        this.moons[mushroom46].add_prereq_moon(mushroom45);
        this.moons[mushroom46].set_prereq_moon_count(100);
        let mushroom47 = this.moons.length;
        this.moons.push(new Moon("Power Moon Wizard", KingdomName.Mushroom));
        this.moons[mushroom47].add_prereq_moon(mushroom46);
        this.moons[mushroom47].set_prereq_moon_count(300);
        let mushroom48 = this.moons.length;
        this.moons.push(new Moon("Power Moon Ruler", KingdomName.Mushroom));
        this.moons[mushroom48].add_prereq_moon(mushroom47);
        this.moons[mushroom48].set_prereq_moon_count(600);
        let mushroom49 = this.moons.length;
        this.moons.push(new Moon("Regional Coin Shopper", KingdomName.Mushroom));
        this.moons[mushroom49].add_prereq_moon(mushroom48);
        let mushroom50 = this.moons.length;
        this.moons.push(new Moon("Flat Moon Finder", KingdomName.Mushroom));
        this.moons[mushroom50].add_prereq_moon(mushroom49);
        this.moons[mushroom50].add_prereq_moon(cascade4);
        this.moons[mushroom50].add_prereq_moon(cascade17);
        this.moons[mushroom50].add_prereq_moon(cascade35);
        this.moons[mushroom50].add_prereq_moon(sand9);
        this.moons[mushroom50].add_prereq_moon(sand10);
        this.moons[mushroom50].add_prereq_moon(cloud8);
        this.moons[mushroom50].add_prereq_moon(cloud9);
        this.moons[mushroom50].add_prereq_moon(lost6);
        this.moons[mushroom50].add_prereq_moon(metro36);
        this.moons[mushroom50].add_prereq_moon(metro64);
        let mushroom51 = this.moons.length;
        this.moons.push(new Moon("Flat Moon Fanatic", KingdomName.Mushroom));
        this.moons[mushroom51].add_prereq_moon(mushroom50);
        this.moons[mushroom51].add_prereq_moon(metro65);
        this.moons[mushroom51].add_prereq_moon(snow30);
        this.moons[mushroom51].add_prereq_moon(seaside8);
        this.moons[mushroom51].add_prereq_moon(seaside9);
        this.moons[mushroom51].add_prereq_moon(seaside27);
        this.moons[mushroom51].add_prereq_moon(seaside65);
        this.moons[mushroom51].add_prereq_moon(luncheon21);
        this.moons[mushroom51].add_prereq_moon(ruined4);
        this.moons[mushroom51].add_prereq_moon(bowser31);
        this.moons[mushroom51].add_prereq_moon(bowser32);
        let mushroom52 = this.moons.length;
        this.moons.push(new Moon("Treasure Chest Hunter", KingdomName.Mushroom));
        this.moons[mushroom52].add_prereq_moon(mushroom51);
        this.moons[mushroom52].add_prereq_moon(cascade6);
        this.moons[mushroom52].add_prereq_moon(sand24);
        this.moons[mushroom52].add_prereq_moon(sand46);
        this.moons[mushroom52].add_prereq_moon(sand47);
        this.moons[mushroom52].add_prereq_moon(sand51);
        this.moons[mushroom52].add_prereq_moon(lake8);
        this.moons[mushroom52].add_prereq_moon(lake25);
        this.moons[mushroom52].add_prereq_moon(wooded26);
        this.moons[mushroom52].add_prereq_moon(wooded35);
        this.moons[mushroom52].add_prereq_moon(wooded36);
        this.moons[mushroom52].add_prereq_moon(metro34);
        this.moons[mushroom52].add_prereq_moon(metro48);
        this.moons[mushroom52].add_prereq_moon(snow11);
        this.moons[mushroom52].add_prereq_moon(snow12);
        this.moons[mushroom52].add_prereq_moon(snow33);
        let mushroom53 = this.moons.length;
        this.moons.push(new Moon("Super Treasure Chest Hunter", KingdomName.Mushroom));
        this.moons[mushroom53].add_prereq_moon(mushroom52);
        this.moons[mushroom53].add_prereq_moon(seaside21);
        this.moons[mushroom53].add_prereq_moon(seaside22);
        this.moons[mushroom53].add_prereq_moon(seaside46);
        this.moons[mushroom53].add_prereq_moon(luncheon34);
        this.moons[mushroom53].add_prereq_moon(ruined2);
        this.moons[mushroom53].add_prereq_moon(bowser30);
        this.moons[mushroom53].add_prereq_moon(moon6);
        this.moons[mushroom53].add_prereq_moon(moon13);
        this.moons[mushroom53].add_prereq_moon(moon24);
        this.moons[mushroom53].add_prereq_moon(mushroom28);
        let mushroom54 = this.moons.length;
        this.moons.push(new Moon("Note-Collecting World Tour", KingdomName.Mushroom));
        this.moons[mushroom54].add_prereq_moon(mushroom53);
        this.moons[mushroom54].add_prereq_moon(cap27);
        this.moons[mushroom54].add_prereq_moon(cascade35);
        this.moons[mushroom54].add_prereq_moon(sand32);
        this.moons[mushroom54].add_prereq_moon(sand79);
        this.moons[mushroom54].add_prereq_moon(sand80);
        let mushroom55 = this.moons.length;
        this.moons.push(new Moon("Note-Collecting Space Tour", KingdomName.Mushroom));
        this.moons[mushroom55].add_prereq_moon(mushroom54);
        this.moons[mushroom55].add_prereq_moon(lake13);
        this.moons[mushroom55].add_prereq_moon(lake14);
        this.moons[mushroom55].add_prereq_moon(wooded67);
        this.moons[mushroom55].add_prereq_moon(wooded68);
        this.moons[mushroom55].add_prereq_moon(cloud6);
        this.moons[mushroom55].add_prereq_moon(lost29);
        this.moons[mushroom55].add_prereq_moon(snow16);
        this.moons[mushroom55].add_prereq_moon(seaside31);
        this.moons[mushroom55].add_prereq_moon(seaside65);
        this.moons[mushroom55].add_prereq_moon(seaside66);
        this.moons[mushroom55].add_prereq_moon(luncheon23);
        this.moons[mushroom55].add_prereq_moon(luncheon36);
        this.moons[mushroom55].add_prereq_moon(luncheon46);
        this.moons[mushroom55].add_prereq_moon(bowser18);
        this.moons[mushroom55].add_prereq_moon(bowser54);
        this.moons[mushroom55].add_prereq_moon(bowser55);
        this.moons[mushroom55].add_prereq_moon(moon8);
        this.moons[mushroom55].add_prereq_moon(moon34);
        this.moons[mushroom55].add_prereq_moon(mushroom11);
        let mushroom56 = this.moons.length;
        this.moons.push(new Moon("Timer Challenge Amateur", KingdomName.Mushroom));
        this.moons[mushroom56].add_prereq_moon(mushroom55);
        this.moons[mushroom56].add_prereq_moon(cap3);
        this.moons[mushroom56].add_prereq_moon(cap28);
        this.moons[mushroom56].add_prereq_moon(cascade9);
        this.moons[mushroom56].add_prereq_moon(cascade10);
        this.moons[mushroom56].add_prereq_moon(sand28);
        this.moons[mushroom56].add_prereq_moon(sand29);
        this.moons[mushroom56].add_prereq_moon(sand30);
        this.moons[mushroom56].add_prereq_moon(lake10);
        this.moons[mushroom56].add_prereq_moon(lake11);
        this.moons[mushroom56].add_prereq_moon(wooded37);
        this.moons[mushroom56].add_prereq_moon(wooded38);
        this.moons[mushroom56].add_prereq_moon(wooded65);
        this.moons[mushroom56].add_prereq_moon(lost31);
        this.moons[mushroom56].add_prereq_moon(metro19);
        this.moons[mushroom56].add_prereq_moon(metro20);
        let mushroom57 = this.moons.length;
        this.moons.push(new Moon("Timer Challenge Professional", KingdomName.Mushroom));
        this.moons[mushroom57].add_prereq_moon(mushroom56);
        this.moons[mushroom57].add_prereq_moon(metro71);
        this.moons[mushroom57].add_prereq_moon(snow13);
        this.moons[mushroom57].add_prereq_moon(snow14);
        this.moons[mushroom57].add_prereq_moon(snow47);
        this.moons[mushroom57].add_prereq_moon(seaside27);
        this.moons[mushroom57].add_prereq_moon(seaside28);
        this.moons[mushroom57].add_prereq_moon(seaside64);
        this.moons[mushroom57].add_prereq_moon(luncheon18);
        this.moons[mushroom57].add_prereq_moon(luncheon19);
        this.moons[mushroom57].add_prereq_moon(luncheon20);
        this.moons[mushroom57].add_prereq_moon(bowser17);
        this.moons[mushroom57].add_prereq_moon(bowser53);
        this.moons[mushroom57].add_prereq_moon(moon7);
        this.moons[mushroom57].add_prereq_moon(moon31);
        this.moons[mushroom57].add_prereq_moon(mushroom9);
        let mushroom58 = this.moons.length;
        this.moons.push(new Moon("Captain Toad Meeter", KingdomName.Mushroom));
        this.moons[mushroom58].add_prereq_moon(mushroom57);
        this.moons[mushroom58].add_prereq_moon(cap4);
        this.moons[mushroom58].add_prereq_moon(cascade11);
        this.moons[mushroom58].add_prereq_moon(sand37);
        this.moons[mushroom58].add_prereq_moon(lake18);
        this.moons[mushroom58].add_prereq_moon(wooded20);
        let mushroom59 = this.moons.length;
        this.moons.push(new Moon("Captain Toad Greeter", KingdomName.Mushroom));
        this.moons[mushroom59].add_prereq_moon(mushroom58);
        this.moons[mushroom59].add_prereq_moon(lost20);
        this.moons[mushroom59].add_prereq_moon(metro24);
        this.moons[mushroom59].add_prereq_moon(snow19);
        this.moons[mushroom59].add_prereq_moon(seaside34);
        this.moons[mushroom59].add_prereq_moon(luncheon33);
        let mushroom60 = this.moons.length;
        this.moons.push(new Moon("Touring with Princess Peach", KingdomName.Mushroom));
        this.moons[mushroom60].add_prereq_moon(mushroom59);
        this.moons[mushroom60].add_prereq_moon(cap16);
        this.moons[mushroom60].add_prereq_moon(cascade21);
        this.moons[mushroom60].add_prereq_moon(sand69);
        this.moons[mushroom60].add_prereq_moon(lake33);
        this.moons[mushroom60].add_prereq_moon(cloud2);
        let mushroom61 = this.moons.length;
        this.moons.push(new Moon("Globe-Trotting with Princess Peach", KingdomName.Mushroom));
        this.moons[mushroom61].add_prereq_moon(mushroom60);
        this.moons[mushroom61].add_prereq_moon(lost25);
        this.moons[mushroom61].add_prereq_moon(metro66);
        this.moons[mushroom61].add_prereq_moon(snow37);
        this.moons[mushroom61].add_prereq_moon(seaside52);
        this.moons[mushroom61].add_prereq_moon(luncheon56);
        let mushroom62 = this.moons.length;
        this.moons.push(new Moon("Master Sheep Herder", KingdomName.Mushroom));
        this.moons[mushroom62].add_prereq_moon(mushroom61);
        this.moons[mushroom62].add_prereq_moon(sand33);
        this.moons[mushroom62].add_prereq_moon(wooded73);
        this.moons[mushroom62].add_prereq_moon(wooded74);
        this.moons[mushroom62].add_prereq_moon(mushroom12);
        let mushroom63 = this.moons.length;
        this.moons.push(new Moon("Gaga for Goombette", KingdomName.Mushroom));
        this.moons[mushroom63].add_prereq_moon(mushroom62);
        this.moons[mushroom63].add_prereq_moon(sand35);
        this.moons[mushroom63].add_prereq_moon(sand81);
        this.moons[mushroom63].add_prereq_moon(lake39);
        this.moons[mushroom63].add_prereq_moon(wooded21);
        this.moons[mushroom63].add_prereq_moon(seaside32);
        this.moons[mushroom63].add_prereq_moon(luncheon24);
        this.moons[mushroom63].add_prereq_moon(mushroom16);
        let mushroom64 = this.moons.length;
        this.moons.push(new Moon("Lakitu Fishing Trip", KingdomName.Mushroom));
        this.moons[mushroom64].add_prereq_moon(mushroom63);
        this.moons[mushroom64].add_prereq_moon(sand34);
        this.moons[mushroom64].add_prereq_moon(sand37);
        this.moons[mushroom64].add_prereq_moon(lake15);
        this.moons[mushroom64].add_prereq_moon(snow17);
        this.moons[mushroom64].add_prereq_moon(bowser27);
        let mushroom65 = this.moons.length;
        this.moons.push(new Moon("Flower-Growing Guru", KingdomName.Mushroom));
        this.moons[mushroom65].add_prereq_moon(mushroom64);
        this.moons[mushroom65].add_prereq_moon(sand25);
        this.moons[mushroom65].add_prereq_moon(sand26);
        this.moons[mushroom65].add_prereq_moon(sand27);
        this.moons[mushroom65].add_prereq_moon(lake9);
        this.moons[mushroom65].add_prereq_moon(metro21);
        let mushroom66 = this.moons.length;
        this.moons.push(new Moon("Flower-Growing Sage", KingdomName.Mushroom));
        this.moons[mushroom66].add_prereq_moon(mushroom65);
        this.moons[mushroom66].add_prereq_moon(metro22);
        this.moons[mushroom66].add_prereq_moon(metro23);
        this.moons[mushroom66].add_prereq_moon(seaside23);
        this.moons[mushroom66].add_prereq_moon(seaside24);
        this.moons[mushroom66].add_prereq_moon(seaside25);
        let mushroom67 = this.moons.length;
        this.moons.push(new Moon("Running with Rabbits", KingdomName.Mushroom));
        this.moons[mushroom67].add_prereq_moon(mushroom66);
        this.moons[mushroom67].add_prereq_moon(cap26);
        this.moons[mushroom67].add_prereq_moon(cascade34);
        this.moons[mushroom67].add_prereq_moon(sand77);
        this.moons[mushroom67].add_prereq_moon(wooded8);
        this.moons[mushroom67].add_prereq_moon(lost13);
        let mushroom68 = this.moons.length;
        this.moons.push(new Moon("Racing with Rabbits", KingdomName.Mushroom));
        this.moons[mushroom68].add_prereq_moon(mushroom67);
        this.moons[mushroom68].add_prereq_moon(snow10);
        this.moons[mushroom68].add_prereq_moon(luncheon35);
        this.moons[mushroom68].add_prereq_moon(bowser15);
        this.moons[mushroom68].add_prereq_moon(moon5);
        this.moons[mushroom68].add_prereq_moon(mushroom3);
        let mushroom69 = this.moons.length;
        this.moons.push(new Moon("Ground Pound Instructor", KingdomName.Mushroom));
        this.moons[mushroom69].add_prereq_moon(mushroom68);
        this.moons[mushroom69].add_prereq_moon(cap17);
        this.moons[mushroom69].add_prereq_moon(cap24);
        this.moons[mushroom69].add_prereq_moon(cascade26);
        this.moons[mushroom69].add_prereq_moon(cascade31);
        this.moons[mushroom69].add_prereq_moon(cascade32);
        this.moons[mushroom69].add_prereq_moon(sand16);
        this.moons[mushroom69].add_prereq_moon(sand17);
        this.moons[mushroom69].add_prereq_moon(sand18);
        this.moons[mushroom69].add_prereq_moon(sand23);
        this.moons[mushroom69].add_prereq_moon(sand31);
        this.moons[mushroom69].add_prereq_moon(sand40);
        this.moons[mushroom69].add_prereq_moon(sand52);
        this.moons[mushroom69].add_prereq_moon(sand67);
        this.moons[mushroom69].add_prereq_moon(sand75);
        this.moons[mushroom69].add_prereq_moon(lake6);
        this.moons[mushroom69].add_prereq_moon(lake7);
        this.moons[mushroom69].add_prereq_moon(lake27);
        let mushroom70 = this.moons.length;
        this.moons.push(new Moon("Ground Pound Professor", KingdomName.Mushroom));
        this.moons[mushroom70].add_prereq_moon(mushroom69);
        this.moons[mushroom70].add_prereq_moon(lake37);
        this.moons[mushroom70].add_prereq_moon(wooded9);
        this.moons[mushroom70].add_prereq_moon(wooded31);
        this.moons[mushroom70].add_prereq_moon(wooded32);
        this.moons[mushroom70].add_prereq_moon(wooded34);
        this.moons[mushroom70].add_prereq_moon(wooded50);
        this.moons[mushroom70].add_prereq_moon(cloud3);
        this.moons[mushroom70].add_prereq_moon(metro14);
        this.moons[mushroom70].add_prereq_moon(metro15);
        this.moons[mushroom70].add_prereq_moon(metro16);
        this.moons[mushroom70].add_prereq_moon(metro53);
        this.moons[mushroom70].add_prereq_moon(metro72);
        this.moons[mushroom70].add_prereq_moon(metro77);
        this.moons[mushroom70].add_prereq_moon(snow8);
        this.moons[mushroom70].add_prereq_moon(snow9);
        this.moons[mushroom70].add_prereq_moon(snow34);
        this.moons[mushroom70].add_prereq_moon(snow43);
        this.moons[mushroom70].add_prereq_moon(snow44);
        this.moons[mushroom70].add_prereq_moon(snow45);
        this.moons[mushroom70].add_prereq_moon(snow46);
        this.moons[mushroom70].add_prereq_moon(seaside16);
        this.moons[mushroom70].add_prereq_moon(seaside17);
        this.moons[mushroom70].add_prereq_moon(seaside18);
        this.moons[mushroom70].add_prereq_moon(seaside19);
        this.moons[mushroom70].add_prereq_moon(seaside20);
        this.moons[mushroom70].add_prereq_moon(seaside29);
        this.moons[mushroom70].add_prereq_moon(seaside41);
        this.moons[mushroom70].add_prereq_moon(seaside50);
        this.moons[mushroom70].add_prereq_moon(seaside61);
        this.moons[mushroom70].add_prereq_moon(luncheon10);
        this.moons[mushroom70].add_prereq_moon(luncheon11);
        this.moons[mushroom70].add_prereq_moon(luncheon12);
        this.moons[mushroom70].add_prereq_moon(luncheon13);
        this.moons[mushroom70].add_prereq_moon(luncheon22);
        this.moons[mushroom70].add_prereq_moon(luncheon30);
        let mushroom71 = this.moons.length;
        this.moons.push(new Moon("Rad Hatter", KingdomName.Mushroom));
        this.moons[mushroom71].add_prereq_moon(mushroom70);
        this.moons[mushroom71].add_prereq_moon(cascade30);
        this.moons[mushroom71].add_prereq_moon(sand15);
        this.moons[mushroom71].add_prereq_moon(sand55);
        let mushroom72 = this.moons.length;
        this.moons.push(new Moon("Super Rad Hatter", KingdomName.Mushroom));
        this.moons[mushroom72].add_prereq_moon(mushroom71);
        this.moons[mushroom72].add_prereq_moon(seaside59);
        this.moons[mushroom72].add_prereq_moon(seaside60);
        this.moons[mushroom72].add_prereq_moon(ruined6);
        this.moons[mushroom72].add_prereq_moon(bowser12);
        this.moons[mushroom72].add_prereq_moon(bowser48);
        this.moons[mushroom72].add_prereq_moon(moon3);
        this.moons[mushroom72].add_prereq_moon(mushroom2);
        let mushroom73 = this.moons.length;
        this.moons.push(new Moon("Traveling-Bird Herder", KingdomName.Mushroom));
        this.moons[mushroom73].add_prereq_moon(mushroom72);
        this.moons[mushroom73].add_prereq_moon(cascade25);
        this.moons[mushroom73].add_prereq_moon(sand21);
        this.moons[mushroom73].add_prereq_moon(sand22);
        this.moons[mushroom73].add_prereq_moon(lake38);
        this.moons[mushroom73].add_prereq_moon(wooded62);
        this.moons[mushroom73].add_prereq_moon(metro54);
        this.moons[mushroom73].add_prereq_moon(metro70);
        this.moons[mushroom73].add_prereq_moon(seaside62);
        let mushroom74 = this.moons.length;
        this.moons.push(new Moon("Wearing it Well!", KingdomName.Mushroom));
        this.moons[mushroom74].add_prereq_moon(mushroom73);
        this.moons[mushroom74].add_prereq_moon(cascade23);
        this.moons[mushroom74].add_prereq_moon(sand53);
        this.moons[mushroom74].add_prereq_moon(lake21);
        let mushroom75 = this.moons.length;
        this.moons.push(new Moon("Wearing it Great!", KingdomName.Mushroom));
        this.moons[mushroom75].add_prereq_moon(mushroom74);
        this.moons[mushroom75].add_prereq_moon(lake29);
        this.moons[mushroom75].add_prereq_moon(lake30);
        this.moons[mushroom75].add_prereq_moon(lake31);
        this.moons[mushroom75].add_prereq_moon(wooded36);
        this.moons[mushroom75].add_prereq_moon(snow20);
        let mushroom76 = this.moons.length;
        this.moons.push(new Moon("Wearing it Perfect!", KingdomName.Mushroom));
        this.moons[mushroom76].add_prereq_moon(mushroom75);
        this.moons[mushroom76].add_prereq_moon(seaside42);
        this.moons[mushroom76].add_prereq_moon(luncheon52);
        this.moons[mushroom76].add_prereq_moon(bowser31);
        this.moons[mushroom76].add_prereq_moon(bowser32);
        this.moons[mushroom76].add_prereq_moon(moon23);
        this.moons[mushroom76].add_prereq_moon(mushroom27);
        this.moons[mushroom76].add_prereq_moon(mushroom28);
        let mushroom77 = this.moons.length;
        this.moons.push(new Moon("Hat-Seeking Missile", KingdomName.Mushroom));
        this.moons[mushroom77].add_prereq_moon(mushroom76);
        this.moons[mushroom77].add_prereq_moon(sand65);
        this.moons[mushroom77].add_prereq_moon(metro62);
        this.moons[mushroom77].add_prereq_moon(metro75);
        this.moons[mushroom77].add_prereq_moon(snow36);
        this.moons[mushroom77].add_prereq_moon(luncheon54);
        this.moons[mushroom77].add_prereq_moon(mushroom42);
        let mushroom78 = this.moons.length;
        this.moons.push(new Moon("Music Maestro", KingdomName.Mushroom));
        this.moons[mushroom78].add_prereq_moon(mushroom77);
        this.moons[mushroom78].add_prereq_moon(sand64);
        this.moons[mushroom78].add_prereq_moon(wooded52);
        this.moons[mushroom78].add_prereq_moon(metro58);
        this.moons[mushroom78].add_prereq_moon(luncheon51);
        this.moons[mushroom78].add_prereq_moon(mushroom19);
        let mushroom79 = this.moons.length;
        this.moons.push(new Moon("Art Enthusiast", KingdomName.Mushroom));
        this.moons[mushroom79].add_prereq_moon(mushroom78);
        this.moons[mushroom79].add_prereq_moon(cap17);
        this.moons[mushroom79].add_prereq_moon(sand63);
        this.moons[mushroom79].add_prereq_moon(lake27);
        this.moons[mushroom79].add_prereq_moon(metro53);
        this.moons[mushroom79].add_prereq_moon(snow34);
        let mushroom80 = this.moons.length;
        this.moons.push(new Moon("Art Investigator", KingdomName.Mushroom));
        this.moons[mushroom80].add_prereq_moon(mushroom79);
        this.moons[mushroom80].add_prereq_moon(seaside50);
        this.moons[mushroom80].add_prereq_moon(luncheon49);
        this.moons[mushroom80].add_prereq_moon(bowser45);
        this.moons[mushroom80].add_prereq_moon(moon27);
        this.moons[mushroom80].add_prereq_moon(mushroom41);
        let mushroom81 = this.moons.length;
        this.moons.push(new Moon("Slots Machine", KingdomName.Mushroom));
        this.moons[mushroom81].add_prereq_moon(mushroom80);
        this.moons[mushroom81].add_prereq_moon(sand44);
        this.moons[mushroom81].add_prereq_moon(metro28);
        this.moons[mushroom81].add_prereq_moon(luncheon26);
        let mushroom82 = this.moons.length;
        this.moons.push(new Moon("Koopa Freerunning MVP", KingdomName.Mushroom));
        this.moons[mushroom82].add_prereq_moon(mushroom81);
        this.moons[mushroom82].add_prereq_moon(cap15);
        this.moons[mushroom82].add_prereq_moon(cascade22);
        this.moons[mushroom82].add_prereq_moon(sand66);
        this.moons[mushroom82].add_prereq_moon(lake32);
        this.moons[mushroom82].add_prereq_moon(wooded53);
        this.moons[mushroom82].add_prereq_moon(lost24);
        this.moons[mushroom82].add_prereq_moon(metro61);
        this.moons[mushroom82].add_prereq_moon(snow35);
        this.moons[mushroom82].add_prereq_moon(seaside51);
        this.moons[mushroom82].add_prereq_moon(bowser41);
        this.moons[mushroom82].add_prereq_moon(moon22);
        let mushroom83 = this.moons.length;
        this.moons.push(new Moon("Koopa Freerunning Hall of Famer", KingdomName.Mushroom));
        this.moons[mushroom83].add_prereq_moon(mushroom82);
        this.moons[mushroom83].add_prereq_moon(cap29);
        this.moons[mushroom83].add_prereq_moon(cascade36);
        this.moons[mushroom83].add_prereq_moon(sand83);
        this.moons[mushroom83].add_prereq_moon(lake40);
        this.moons[mushroom83].add_prereq_moon(wooded69);
        this.moons[mushroom83].add_prereq_moon(lost30);
        this.moons[mushroom83].add_prereq_moon(metro74);
        this.moons[mushroom83].add_prereq_moon(snow51);
        this.moons[mushroom83].add_prereq_moon(seaside67);
        this.moons[mushroom83].add_prereq_moon(bowser58);
        this.moons[mushroom83].add_prereq_moon(moon33);
        this.moons[mushroom83].add_prereq_moon(mushroom21);
        this.moons[mushroom83].add_prereq_moon(mushroom22);
        let mushroom84 = this.moons.length;
        this.moons.push(new Moon("Supernaturally Sure-Footed", KingdomName.Mushroom));
        this.moons[mushroom84].add_prereq_moon(mushroom83);
        this.moons[mushroom84].add_prereq_moon(sand45);
        this.moons[mushroom84].add_prereq_moon(sand82);
        this.moons[mushroom84].add_prereq_moon(snow22);
        this.moons[mushroom84].add_prereq_moon(snow50);
        this.moons[mushroom84].add_prereq_moon(moon21);
        this.moons[mushroom84].add_prereq_moon(moon32);
        let mushroom85 = this.moons.length;
        this.moons.push(new Moon("Quizmaster", KingdomName.Mushroom));
        this.moons[mushroom85].add_prereq_moon(mushroom84);
        this.moons[mushroom85].add_prereq_moon(sand41);
        this.moons[mushroom85].add_prereq_moon(seaside35);
        this.moons[mushroom85].add_prereq_moon(moon18);
        let mushroom86 = this.moons.length;
        this.moons.push(new Moon("Souvenir Sampler", KingdomName.Mushroom));
        this.moons[mushroom86].add_prereq_moon(mushroom85);
        let mushroom87 = this.moons.length;
        this.moons.push(new Moon("Souvenir Sleuth", KingdomName.Mushroom));
        this.moons[mushroom87].add_prereq_moon(mushroom86);
        let mushroom88 = this.moons.length;
        this.moons.push(new Moon("Souvenir Savant", KingdomName.Mushroom));
        this.moons[mushroom88].add_prereq_moon(mushroom87);
        let mushroom89 = this.moons.length;
        this.moons.push(new Moon("Capturing Novice", KingdomName.Mushroom));
        this.moons[mushroom89].add_prereq_moon(mushroom88);
        let mushroom90 = this.moons.length;
        this.moons.push(new Moon("Capturing Apprentice", KingdomName.Mushroom));
        this.moons[mushroom90].add_prereq_moon(mushroom89);
        let mushroom91 = this.moons.length;
        this.moons.push(new Moon("Capturing Master", KingdomName.Mushroom));
        this.moons[mushroom91].add_prereq_moon(mushroom90);
        let mushroom92 = this.moons.length;
        this.moons.push(new Moon("Hat Maven", KingdomName.Mushroom));
        this.moons[mushroom92].add_prereq_moon(mushroom91);
        let mushroom93 = this.moons.length;
        this.moons.push(new Moon("Hat Icon", KingdomName.Mushroom));
        this.moons[mushroom93].add_prereq_moon(mushroom92);
        let mushroom94 = this.moons.length;
        this.moons.push(new Moon("Fashion Maven", KingdomName.Mushroom));
        this.moons[mushroom94].add_prereq_moon(mushroom93);
        let mushroom95 = this.moons.length;
        this.moons.push(new Moon("Fashion Icon", KingdomName.Mushroom));
        this.moons[mushroom95].add_prereq_moon(mushroom94);
        let mushroom96 = this.moons.length;
        this.moons.push(new Moon("Moon Rock Liberator", KingdomName.Mushroom));
        this.moons[mushroom96].add_prereq_moon(mushroom95);
        let mushroom97 = this.moons.length;
        this.moons.push(new Moon("World Warper", KingdomName.Mushroom));
        this.moons[mushroom97].add_prereq_moon(mushroom96);
        this.moons[mushroom97].add_prereq_moon(cascade18);
        this.moons[mushroom97].add_prereq_moon(sand62);
        this.moons[mushroom97].add_prereq_moon(lake26);
        this.moons[mushroom97].add_prereq_moon(wooded49);
        this.moons[mushroom97].add_prereq_moon(metro51);
        this.moons[mushroom97].add_prereq_moon(snow33);
        this.moons[mushroom97].add_prereq_moon(seaside49);
        this.moons[mushroom97].add_prereq_moon(luncheon47);
        this.moons[mushroom97].add_prereq_moon(bowser43);
        this.moons[mushroom97].add_prereq_moon(mushroom39);
        let mushroom98 = this.moons.length;
        this.moons.push(new Moon("Checkpoint Flagger", KingdomName.Mushroom));
        this.moons[mushroom98].add_prereq_moon(mushroom97);
        let mushroom99 = this.moons.length;
        this.moons.push(new Moon("Checkpoint Flag Enthusiast", KingdomName.Mushroom));
        this.moons[mushroom99].add_prereq_moon(mushroom98);
        let mushroom100 = this.moons.length;
        this.moons.push(new Moon("Loaded with Coins", KingdomName.Mushroom));
        this.moons[mushroom100].add_prereq_moon(mushroom99);
        let mushroom101 = this.moons.length;
        this.moons.push(new Moon("Rolling in Coins", KingdomName.Mushroom));
        this.moons[mushroom101].add_prereq_moon(mushroom100);
        let mushroom102 = this.moons.length;
        this.moons.push(new Moon("Swimming in Coins", KingdomName.Mushroom));
        this.moons[mushroom102].add_prereq_moon(mushroom101);
        let mushroom103 = this.moons.length;
        this.moons.push(new Moon("Jump! Jump! Jump!", KingdomName.Mushroom));
        this.moons[mushroom103].add_prereq_moon(mushroom102);
        let mushroom104 = this.moons.length;
        this.moons.push(new Moon("Fly, Cappy, Fly!", KingdomName.Mushroom));
        this.moons[mushroom104].add_prereq_moon(mushroom103);
        let dark17 = this.moons.length;
        this.moons.push(new Moon("Found with Dark Side Art 3", KingdomName.Mushroom));
        this.moons[dark17].add_prereq_kingdom(KingdomName.Dark);
        this.offset.push([mushroom1, this.moons.length]);

        // dark side
        let dark1 = this.moons.length;
        this.moons.push(new Moon("Arrival at Rabbit Ridge!", KingdomName.Dark, true));
        kingdoms.kingdom(KingdomName.Dark).set_exit_moon(dark1);
        let dark2 = this.moons.length;
        this.moons.push(new Moon("Captain Toad on the Dark Side!", KingdomName.Dark));
        this.moons[dark1].add_prereq_moon(dark2);
        let dark3 = this.moons.length;
        this.moons.push(new Moon("Breakdown Road: Hurry!", KingdomName.Dark));
        this.moons[dark3].add_prereq_moon(dark1);
        let dark4 = this.moons.length;
        this.moons.push(new Moon("Breakdown Road: Final Challenge!", KingdomName.Dark));
        this.moons[dark4].add_prereq_moon(dark1);
        let dark5 = this.moons.length;
        this.moons.push(new Moon("Invisible Road: Rush!", KingdomName.Dark));
        this.moons[dark5].add_prereq_moon(dark1);
        let dark6 = this.moons.length;
        this.moons.push(new Moon("Invisible Road: Secret!", KingdomName.Dark));
        this.moons[dark6].add_prereq_moon(dark1);
        let dark7 = this.moons.length;
        this.moons.push(new Moon("Vanishing Road Rush", KingdomName.Dark));
        this.moons[dark7].add_prereq_moon(dark1);
        let dark8 = this.moons.length;
        this.moons.push(new Moon("Vanishing Road Challenge", KingdomName.Dark));
        this.moons[dark8].add_prereq_moon(dark1);
        let dark9 = this.moons.length;
        this.moons.push(new Moon("Yoshi Under Siege", KingdomName.Dark));
        this.moons[dark9].add_prereq_moon(dark1);
        let dark10 = this.moons.length;
        this.moons.push(new Moon("Fruit Feast Under Siege", KingdomName.Dark));
        this.moons[dark10].add_prereq_moon(dark1);
        this.moons[dark10].add_prereq_moon(dark9);
        let dark11 = this.moons.length;
        this.moons.push(new Moon("Yoshi on the Sinking Island", KingdomName.Dark));
        this.moons[dark11].add_prereq_moon(dark1);
        let dark12 = this.moons.length;
        this.moons.push(new Moon("Fruit Feast on the Sinking Island", KingdomName.Dark));
        this.moons[dark12].add_prereq_moon(dark1);
        this.moons[dark12].add_prereq_moon(dark11);
        let dark13 = this.moons.length;
        this.moons.push(new Moon("Yoshi's Magma Swamp", KingdomName.Dark));
        this.moons[dark13].add_prereq_moon(dark1);
        let dark14 = this.moons.length;
        this.moons.push(new Moon("Fruit Feast in the Magma Swamp!", KingdomName.Dark));
        this.moons[dark14].add_prereq_moon(dark1);
        this.moons[dark14].add_prereq_moon(dark13);
        this.moons[dark15].add_prereq_moon(dark1);
        this.moons[dark16].add_prereq_moon(dark1);
        this.moons[dark17].add_prereq_moon(dark1);
        this.moons[dark18].add_prereq_moon(dark1);
        this.moons[dark19].add_prereq_moon(dark1);
        this.moons[dark20].add_prereq_moon(dark1);
        this.moons[dark21].add_prereq_moon(dark1);
        this.moons[dark22].add_prereq_moon(dark1);
        this.moons[dark23].add_prereq_moon(dark1);
        this.moons[dark24].add_prereq_moon(dark1);
        this.moons[mushroom80].add_prereq_moon(dark15);
        this.moons[mushroom80].add_prereq_moon(dark16);
        this.moons[mushroom80].add_prereq_moon(dark17);
        this.moons[mushroom80].add_prereq_moon(dark18);
        this.moons[mushroom80].add_prereq_moon(dark19);
        this.moons[mushroom80].add_prereq_moon(dark20);
        this.moons[mushroom80].add_prereq_moon(dark21);
        this.moons[mushroom80].add_prereq_moon(dark22);
        this.moons[mushroom80].add_prereq_moon(dark23);
        this.moons[mushroom80].add_prereq_moon(dark24);
        this.offset.push([dark1, this.moons.length]);

        // darker side
        let darker1 = this.moons.length;
        this.moons.push(new Moon("Long Journey's End", KingdomName.Darker, true));
        this.offset.push([darker1, this.moons.length]);

        // setup the ids - initially monotonic
        this.ids = [];
        for (let x = 0; x < this.moons.length; x++) {
            this.ids.push(x);
        }

    }

}
